import { makeAutoObservable } from 'mobx'

import { userModel } from './userModel';
import { adminModel } from './adminModel';
import { authModel } from './authModel';
import { usersAdminModel } from './usersAdminModel';
import { countersAdminModel } from './countersAdminModel';

export class RootStore {
    constructor() {
        makeAutoObservable(this)
    }

    authModel = authModel;
    userModel = userModel;
    adminModel = adminModel;
    usersAdminModel = usersAdminModel;
    countersAdminModel = countersAdminModel;
}

export const rootStore = new RootStore();
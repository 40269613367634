import React, { useEffect } from 'react';
import { inject, observer } from "mobx-react";
// import Loader from "react-loader-spinner";

import ApiService from '../../service/ApiService';
import Pagination from '../dashboard/AdminDashboard/pagination/Pagination';
import ImgBuilder from '../../img/ImgBuilder';
import DeletePopup from '../../components/popups/DeletePopup/DeletePopup';
import CreateCounter from '../../components/popups/CreateCounterPopup/CreateCounter';
import EditCounter from '../../components/popups/EditCounterPopup/EditCounter';
import BasicButton from '../../res/BasicButton/BasicButton';

import './Counters.scss';

const Counters = (props) => {
  const {
    countersAdminModel: {
      getCounters, counters, pagination, changeFilter, refreshFilter,
      filter: {
        search, type,
      }
    }
  } = props;

  useEffect(() => {
    getCounters();
  }, [type, search]);

  useEffect(() => {
    return () => refreshFilter();
  }, []);

  const removeCounter = (id) => {
    ApiService.removeCounter(id).then(() => {
      getCounters();
    })
  }

  // if (counters.length === 0 && search === '') {
  //   return (
  //     <div className="loader_wrapper">
  //       <Loader color="#007BB0" type='BallTriangle' width={150} height={150} />
  //     </div>
  //   )
  // }
  return (
    <div className="counters_page">
      <div className="counters_dashboard_list">
        <div className="counters_header">
          <div className="create_counter_wrapper">
            <CreateCounter refresh={getCounters} >
              <BasicButton label="Створити лічильник" width="156px" />
            </CreateCounter>
          </div>

          <div className="search">
            <input
              className="counters_search"
              onChange={(e) => changeFilter('search', e.target.value)}
              placeholder="Пошук лічильника"
              onKeyPress={(e) => {
                if (e.key === "Enter")
                  getCounters();
              }}
              type="text"
              value={search} />
            <BasicButton onClick={getCounters} label="Шукати" width="100px" />
          </div>
        </div>
        <div className="pagination_container pagination_container_top">
          <Pagination pagination={pagination} getNextPage={(page) => getCounters(page)} />
        </div>

        <div className="counters_wrapper">
          <table className="counters_table">
            <thead>
              <tr>
                <th className="counters_filters">
                  <span>Номер лічильника</span>
                  <div className="filters">
                    <img
                      className={`${type === "ASC" ? "active" : ""}`}
                      src={ImgBuilder.topFilter} alt="ASC"
                      onClick={() => changeFilter('type', 'ASC')}
                    />
                    <img
                      className={`${type === "DESC" ? "active" : ""}`}
                      src={ImgBuilder.downFilter} alt="DESC"
                      onClick={() => changeFilter('type', 'DESC')}
                    />
                  </div>
                </th>
                <th className="quarter_number_td">Квартал</th>
                <th className="plomb_number_td">Номер пломби</th>
                <th className="adress_td">Вулиця</th>
                <th className="name_td">ПІБ</th>
                <th className="last_number_th">Початковий показник</th>
                <th className="status_td">Статус</th>
                <th className="counter_menu_td"></th>
              </tr>
            </thead>
            <tbody>
              {counters.map((item, key) => (
                <tr key={key}>
                  <td className="counter_number_td">{item.counter_number}</td>
                  <td className="quarter_number_td">{item.quarter_name ? item.quarter_name : '-'}</td>
                  <td className="plomb_number_td">{item.plomb_number}</td>
                  <td className="adress_td">{item.street ? `${item.street} ${item.building_number}` : '-'}</td>
                  <td className="name_td">{item.last_name && item.name && item.middle_name ? `${item.last_name} ${item.name} ${item.middle_name}` : '-'}</td>
                  <td className="last_number_td">{item.last_number}</td>
                  <td className="status_td">{item.is_used ? "Зареєстрований" : "Не зареєстрований"}</td>
                  <td className="counter_menu_td">
                    {item.is_used
                      ? ""
                      : <DeletePopup remove={() => removeCounter(item.id)} >
                        <span className="delete">Видалити</span>
                      </DeletePopup>
                    }
                    <EditCounter counter={item} refresh={getCounters}>
                      <span className="edit">Редагувати</span>
                    </EditCounter>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="pagination_container">
          <Pagination pagination={pagination} getNextPage={(page) => getCounters(page)} />
        </div>
      </div>
    </div>
  )
}

export default inject('countersAdminModel')(observer(Counters));
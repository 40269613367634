import eye from './eye.svg';
import done from './done.png';
import search from './search.svg';
import arrow_active from './arrow_active.svg';
import arrow_disabled from './arrow_disabled.svg';
import comentar from './comentar.svg';
import mail from './mail.svg';
import facebook from './facebook.svg';
import instagram from './instagram.svg';
import twitter from './twitter.svg';
import downFilter from './downFilter.svg';
import topFilter from './topFilter.svg';
import ok from './ok.svg';
import hourglass from './hourglass.svg';
import edit from './edit.svg';
import del from './delete.svg';
import close from './close.svg';

const ImgBuilder = {
    eye,
    done,
    search,
    arrow_active,
    arrow_disabled,
    comentar,
    mail,
    facebook,
    instagram,
    twitter,
    downFilter,
    topFilter,
    ok,
    hourglass,
    edit,
    del,
    close,
}

export default ImgBuilder;

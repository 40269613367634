import React from 'react';
import Popup from 'reactjs-popup';

import './style.scss';

const ShowAttachment = ({ isOpen, setOpen, attachment }) => {
  return (
    <Popup
      className="popup_show_attachment"
      modal
      open={isOpen}
      overlayStyle={{ background: 'rgba(0,0,0,0.5)' }}
      onClose={() => setOpen(false)}
      closeOnDocumentClick={true}
    >
      {close => (
        <div className="show_attachment">
          <img src={attachment} alt="attachment" className="img_attachment" />
        </div>
      )}
    </Popup>
  )
}

export default ShowAttachment;

import { api } from './api';
import axios from 'axios';
import * as https from 'https';
import { authModel } from '../stores/authModel';

const url = (endpoint) => {
    return `${api}${endpoint}`
}

const headers = {
    'Content-Type': 'application/json',
    'Authorization': authModel.sessionToken,
}
const headersFormData = {
    'Content-Type': 'multipart/form-data',
    'Authorization': authModel.sessionToken,
}

const agent = new https.Agent({
    rejectUnauthorized: false
});

const ApiService = {
    signUp: (data) => {
        return axios.post(url("sign_up"), data, {
            headers: {
                'Content-Type': 'application/json'
            }
        }, {
            httpsAgent: agent
        })

    },
    signIn: (data) => {
        return axios.post(url("sign_in"), data, {
            headers: {
                'Content-Type': 'application/json'
            }
        }, {
            httpsAgent: agent
        })

    },
    saveUserInfo: (data) => {
        return axios.put(url(`profiles/${authModel.user.id}`), data, {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    getAllUsers: (page, search, isDebtor, type, isApproved, quarter, isQuarter, isAwaitingConfrim) => {
        return axios.get(url(`admin/users?page=${page}&debtor_filter=${isDebtor}&sort_column=counter_number&sort_type=${type}&counter_number_filter=${search}&user_unapproved_filter=${isApproved}&quarter_filter=${quarter}&without_quarter_filter=${isQuarter}&awaiting_confirmation_filter=${isAwaitingConfrim}`), {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    updateComentar: (profileId, text) => {
        return axios.post(url(`admin/update_notification?id=${profileId}&text=${text}`), {}, {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    getUserInfo: (userId) => {
        return axios.get(url(`admin/users/${userId}`), {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    changeUserStatus: (status, userId) => {
        return axios.post(url(`admin/${status}?id=${userId}`), {}, {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    changePaymentStatus: (billId, status) => {
        return axios.put(url(`admin/bills/${billId}/change_payment_status`), {
            status: status
        }, {
            headers: headers
        })
    },
    getSettings: () => {
        return axios.get(url(`admin/settings`), {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    updateSettings: (settings) => {
        return axios.post(url(`admin/settings`), settings, {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    getCounters: (page, search, type) => {
        return axios.get(url(`admin/counters?page=${page}&sort_column=counter_number&sort_type=${type}&search=${search}`), {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    removeCounter: (id) => {
        return axios.delete(url(`admin/counters/${id}`), {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    createCounter: (data) => {
        return axios.post(url(`admin/counters`), data, {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    editCounter: (id, data) => {
        return axios.put(url(`admin/counters/${id}`), data, {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    getUserProfile: () => {
        return axios.get(url(`get_profile`), {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    updateUserProfile: (profile_id, data, isAdmin) => {
        const reqUrl = isAdmin ? `admin/users/${profile_id}` : `profiles/${profile_id}`;
        return axios.put(url(reqUrl), data, {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    changePassword: (data) => {
        return axios.put(url(`passwords`), data, {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    changeEmail: (data) => {
        return axios.post(url(`change_email`), data, {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    forgot_password: (data) => {
        return axios.post(url(`forgot_passwords`), data, {
            headers: headersFormData
        }, {
            httpsAgent: agent
        })
    },
    reset_password: (data) => {
        return axios.patch(url(`forgot_passwords`), data, {
            headers: headersFormData
        }, {
            httpsAgent: agent
        })
    },
    editCounterResult: (id, data) => {
        return axios.put(url(`admin/update_last_result/${id}`), data, {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    saveCounterResult: (data) => {
        return axios.post(url(`results`), data, {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    getAllResults: () => {
        return axios.get(url(`results`), {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    getLastResult: () => {
        return axios.get(url(`get_last_result`), {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    getAllBills: () => {
        return axios.get(url(`bills`), {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    getBills: (params) => {
        return axios.get(url(`bills?paid=${params}`), {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    payBill: (id) => {
        return axios.post(url(`checkouts?id=${id}`), {}, {
            headers: headers
        })
    },
    askQuiestion: (data) => {
        return axios.post(url(`feedbacks`), data, {
            headers: headersFormData
        }, {
            httpsAgent: agent
        })
    },
    getQuestions: (page) => {
        return axios.get(url(`admin/feedbacks?page=${page}`), {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    confrimQuestionAnswered: (id, answer) => {
        const confirmJson = {
            feedback: {
                status: "answered",
                answer: `${answer}`,
            }
        }
        return axios.put(url(`admin/feedbacks/${id}`), confirmJson, {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    getPrice: () => {
        return axios.get(url(`/settings`), {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    sendReminder: (id) => {
        return axios.post(url(`admin/send_reminder/${id}`), {}, {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    getBillsResultsByYear: (id, year) => {
        return axios.get(url(`admin/users/${id}?by_year_bills=${year}&by_year_results=${year}`), {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    updateLastResult: (id) => {
        return axios.get(url(`admin/update_last_result/${id}`), {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },

    confirmChangesPersData: (id, data) => {
        return axios.put(url(`admin/confirmation_new_data/${id}`), data, {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },

    updateQuarters: (data) => {
        return axios.post(url(`admin/quarters`), data, {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    editQuarters: (data, id) => {
        return axios.put(url(`admin/quarters/${id}`), data, {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    removeQuarters: (id) => {
        return axios.delete(url(`admin/quarters/${id}`), {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    getQuarters: () => {
        return axios.get(url(`admin/quarters`), {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    confirmEmail: (token) => {
        return axios.get(url(`confirm_email?token=${token}`), {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    confirmChangeOldEmail: (token) => {
        return axios.get(url(`confirm_change_old_email?token=${token}`), {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },

    confirmChangeNewEmail: (token) => {
        return axios.get(url(`confirm_change_email?token=${token}`), {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    getAllQuestions: () => {
        return axios.get(url(`feedbacks`), {
            headers: headers
        }, {
            httpsAgent: agent
        })

    },
    payAllBills: (data) => {
        return axios.post(url(`/checkouts/pay`), data, {
            headers: headers
        }, {
            httpsAgent: agent
        })
    },
    resentConfirmEmail: () => {
        return axios.get(url(`resend_confirm_email`), {
            headers: headers
        }, {
            httpsAgent: agent
        })

    },
    addPrepayment: (id, prepayment) => {
        return axios.post(url(`admin/add_amount`), {amount: parseFloat(prepayment), id: id}, {
            headers: headers
        }, {
            httpsAgent: agent
        })

    },
}

export default ApiService;

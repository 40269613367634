import React from 'react';

import './style.scss';

const UserData = ({ title, user }) => {
  return (
    <div className="user_data">
      <h3 className="title">{title}</h3>

      {user.last_name && <div>
        <p className="user_item">Прізвище:</p>
        <p className="user_itemData">{user.last_name}</p>
      </div>}

      {user.name && <div>
        <p className="user_item">Ім'я:</p>
        <p className="user_itemData">{user.name}</p>
      </div>}

      {user.middle_name && <div>
        <p className="user_item">По батькові:</p>
        <p className="user_itemData">{user.middle_name}</p>
      </div>}

      {user.email && <div>
        <p className="user_item">Електронна пошта:</p>
        <p className="user_itemData">{user.email}</p>
      </div>}

      {user.street && <div>
        <p className="user_item">Вулиця:</p>
        <p className="user_itemData">{user.street}</p>
      </div>}

      {user.building_number && <div>
        <p className="user_item">Номер будинку:</p>
        <p className="user_itemData">{user.building_number}</p>
      </div>}

      {(user.flat_number || user.flat_number === '') && <div>
        <p className="user_item">Номер квартири:</p>
        <p className="user_itemData">{user.flat_number === '' ? "Номер квартири видалено" : user.flat_number}</p>
      </div>}

      {user.phone_number && <div>
        <p className="user_item">Номер телефону:</p>
        <p className="user_itemData">{user.phone_number}</p>
      </div>}

    </div>
  );
};

export default UserData;

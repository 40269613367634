import React from 'react';
import Popup from 'reactjs-popup';
import moment from 'moment';
import {gavnoCode} from '../../../const/getLastPayment';

import BasicListItem from '../../../res/BasicListItem/BasicListItem';

import './ShowAllBills.scss';

const ShowAllBills = ({ bills, children, profile }) => {

    return (
        <div className="show-bills-pop-up-wrapper">
            <Popup
                trigger={open => (children)}
                modal
                closeOnDocumentClick={true}
            >
                {close => (
                    <>
                        <div className="show-result-pop-up-header">
                            <h1 className="title">Історія оплат</h1>
                        </div>

                        <div className="list">
                            <div className="list_header">
                            <span>Дата</span>
                            <span>Повна вартість</span>
                            <span>Зараховано(грн)</span>

                            </div>
                            <div className="list_content">
                                {bills.slice(0).reverse().map((item, key) => {
                                    return gavnoCode(item);
                                })}
                                <i style = {{marginTop: '8px'}}>{profile.prepayment !== 0 ? `(Предоплата: ${(profile.prepayment / 100).toFixed(2)})` : ''}</i>

                            </div>
                        </div>
                        <div className="exit" onClick={close}>
                            Закрити
                        </div>
                    </>
                )}
            </Popup >
        </div>
    )
}

export default ShowAllBills;
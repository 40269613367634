import React from 'react';
import { observer, inject } from "mobx-react";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Popup from 'reactjs-popup';

import BasicButton from '../../../res/BasicButton/BasicButton';
import ChangePassword from '../ChangePassword/ChangePassword';
import ChangeEmail from '../ChangeEmail/ChangeEmail';
import { phoneRegExp } from '../../../const/regular_expressions';

import './EditProfilePopup.scss';

const EditProfilePopup = (props) => {
    const { user, children, isAdmin,
        userModel: {
            updateProfile
        },
        usersAdminModel: {
            getAllUsers,
        }
    } = props;

    const getPostData = (user) => {
        if (isAdmin) {
            return {
                profile: {
                    name: user.name,
                    last_name: user.last_name,
                    middle_name: user.middle_name,
                    street: user.street,
                    building_number: user.building_number,
                    flat_number: user.flat_number,
                    phone_number: user.phone_number
                }
            }
        }
        return user
    }
    return (
        <div className="edit-profile-pop-up-wrapper">
            <Popup
                className="edit_profile_popup"
                trigger={open => (children)}
                modal
                closeOnDocumentClick={true}
            >
                {close => (
                    <>
                        <div className="header">
                            <h1 className="title">Редагувати профіль</h1>
                            {isAdmin ? null : <div className="change_data">
                                <ChangeEmail><h1 className="change_password">Змінити email</h1></ChangeEmail>
                                <ChangePassword><h1 className="change_password">Змінити пароль</h1></ChangePassword>
                            </div>}
                        </div>
                        <Formik
                            initialValues={user}
                            validationSchema={Yup.object().shape({
                                last_name: Yup.string().required("Обов'язкове поле").nullable(),
                                name: Yup.string().required("Обов'язкове поле").nullable(),
                                middle_name: Yup.string().required("Обов'язкове поле").nullable(),
                                street: Yup.string().required("Обов'язкове поле").nullable(),
                                building_number: Yup.string().required("Обов'язкове поле").nullable(),
                                phone_number: Yup.string().required("Обов'язкове поле").nullable().matches(phoneRegExp, 'Неправильний номер телефону'),
                            })}
                            onSubmit={user => {
                                let info = getPostData(user);
                                updateProfile(user.id, info, isAdmin, close, getAllUsers);
                            }}
                        >
                            {({ errors, values, handleChange }) => (
                                <Form>
                                    <div className="form_edit_profile">
                                        <div className="form_item">
                                            <h1 className="form_label">Прізвище</h1>
                                            <input
                                                type="text"
                                                placeholder="Введіть прізвище"
                                                className={`form_input ${errors.last_name ? "error" : null}`}
                                                name="last_name"
                                                value={values.last_name}
                                                onChange={handleChange} />
                                            <h1 className="error_text">{errors.last_name ? errors.last_name : ""}</h1>
                                        </div>

                                        <div className="form_item">
                                            <h1 className="form_label">І’мя</h1>
                                            <input
                                                type="text"
                                                placeholder="Введіть ім'я"
                                                className={`form_input ${errors.name ? "error" : null}`}
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange} />
                                            <h1 className="error_text">{errors.name ? errors.name : ""}</h1>
                                        </div>

                                        <div className="form_item">
                                            <h1 className="form_label">По батькові</h1>
                                            <input
                                                type="text"
                                                placeholder="Введіть по батькові"
                                                className={`form_input ${errors.middle_name ? "error" : null}`}
                                                name="middle_name"
                                                value={values.middle_name}
                                                onChange={handleChange} />
                                            <h1 className="error_text">{errors.middle_name ? errors.middle_name : ""}</h1>
                                        </div>

                                        <div className="form_item">
                                            <h1 className="form_label">Вулиця</h1>
                                            <input
                                                type="text"
                                                placeholder="Введіть вулицю"
                                                className={`form_input ${errors.street ? "error" : null}`}
                                                name="street"
                                                value={values.street}
                                                onChange={handleChange} />
                                            <h1 className="error_text">{errors.street ? errors.street : ""}</h1>
                                        </div>

                                        <div className="form_item">
                                            <h1 className="form_label">Номер будинку</h1>
                                            <input
                                                type="text"
                                                placeholder="Введіть номер будинку"
                                                className={`form_input ${errors.building_number ? "error" : null}`}
                                                name="building_number"
                                                value={values.building_number}
                                                onChange={handleChange} />
                                            <h1 className="error_text">{errors.building_number ? errors.building_number : ""}</h1>
                                        </div>

                                        <div className="form_item">
                                            <h1 className="form_label">Номер квартири</h1>
                                            <input
                                                type="text"
                                                placeholder="Введіть номер квартири"
                                                className={`form_input ${errors.flat_number ? "error" : null}`}
                                                name="flat_number"
                                                value={values.flat_number}
                                                onChange={handleChange} />
                                            <h1 className="error_text">{errors.flat_number ? errors.flat_number : ""}</h1>
                                        </div>

                                        <div className="form_item">
                                            <h1 className="form_label">Номер телефону</h1>
                                            <input
                                                type="text"
                                                placeholder="Введіть номер телефону"
                                                className={`form_input ${errors.phone_number ? "error" : null}`}
                                                name="phone_number"
                                                value={values.phone_number}
                                                onChange={handleChange} />
                                            <h1 className="error_text">{errors.phone_number ? errors.phone_number : ""}</h1>
                                        </div>
                                    </div>

                                    <button className="btn_wrapper" onClick={null}>
                                        <BasicButton label="Зберегти" width="125px" />
                                        <div className="cancel" onClick={close}>Скасувати</div>
                                    </button >
                                </Form>
                            )}
                        </Formik>
                    </>
                )}
            </Popup >
        </div>
    )
}

export default inject('userModel', 'usersAdminModel')(observer(EditProfilePopup));
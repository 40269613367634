import React from 'react';
import Popup from 'reactjs-popup';
import moment from 'moment';

import BasicListItem from '../../../res/BasicListItem/BasicListItem';

import './ShowAllResults.scss';

const ShowAllResults = ({ results, children }) => {

    return (
        <div className="show-result-pop-up-wrapper">
            <Popup
                trigger={open => (children)}
                modal
                closeOnDocumentClick={true}
            >
                {close => (
                    <>
                        <div className="show-result-pop-up-header">
                            <h1 className="title">Історія показів</h1>
                        </div>

                        <div className="list">
                            <div className="list_header">
                                <span>Дата</span>
                                <span>Показ</span>
                            </div>
                            <div className="list_content">
                                {results.slice(0).reverse().map((item) => (
                                    <BasicListItem
                                        key={item.id}
                                        label={moment(item.created_at).format("DD.MM.YYYY")}
                                        value={`${item.first_number},${item.last_number ? item.last_number : "00"}`}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className="exit" onClick={close}>
                            Закрити
                        </div>
                    </>
                )}
            </Popup >
        </div>
    )
}

export default ShowAllResults;
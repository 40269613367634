import React from 'react';
import Popup from 'reactjs-popup';
import { Formik } from 'formik';
import * as Yup from 'yup';

import BasicButton from '../../../res/BasicButton/BasicButton';
import ApiService from '../../../service/ApiService';

import './style.scss';

const ConfirmEditQuarterPopup = ({ children, quarter_name, id, refreshAdmin }) => {
  return (
    <div className="confirm_edit_quarter">
      <Popup
        trigger={open => (children)}
        modal
        closeOnDocumentClick={true}
      >
        {close => (
          <Formik
            initialValues={{
              quarter: quarter_name,
            }}
            validationSchema={Yup.object().shape({
              quarter: Yup.string().required('Обов\'язкове поле'),
            })}
            onSubmit={(data) => {
              ApiService.editQuarters({
                quarter: {
                  name: data.quarter.toUpperCase()
                }
              }, id).then(() => {
                close();
                refreshAdmin();
              }).catch(() => alert("Щось пішло не так"))
            }}
          >
            {({ values, errors, handleChange, handleSubmit }) => (
              <div className='edit_quarter_form'>
                <p className="edit_quarter_form_item">
                  <h3 className="label">Редагувати квартал:</h3>
                  <input
                    className={`input_edit_quarter ${errors.quarter ? 'error' : null}`}
                    type="text"
                    placeholder="Введіть назву кварталу"
                    name="quarter"
                    value={values.quarter}
                    onChange={handleChange} />
                  <p className="error_text">{errors.quarter ? errors.quarter : null}</p>
                </p>
                <div className="btn_wrapper">
                  <BasicButton onClick={handleSubmit} label="Редагувати" width='156px' />
                </div>
              </div>
            )}
          </Formik>
        )}
      </Popup >
    </div>
  )
}

export default ConfirmEditQuarterPopup;

import React, { useState } from 'react';
import { inject, observer } from "mobx-react";

import ImgBuilder from '../../../../img/ImgBuilder';
import Comment from './comment/Comment';
import ApiService from '../../../../service/ApiService';
import ShowUserPopup from '../../../../components/popups/ShowUserPopup/ShowUserPopup';
import EditProfilePopup from '../../../../components/popups/EditProfilePopup/EditProfilePopup';
import ConfirmChangesPersData from '../../../../components/popups/ConfirmChangesPersData/ConfirmChangesPersData';

import './AdminDashboardList.scss';

const AdminDashboardList = (props) => {
  const {
    usersAdminModel: {
      users, changeFilter, filter: {
        type,
      }
    }
  } = props;

  const [clickedComentar, setClickedComentar] = useState("");

  const sendReminder = (id) => {
    ApiService.sendReminder(id).then(() => {
      alert("Сповіщення надіслано")
    });
  };

  return (
    <div className="admin_dashboard_list">
      <table>
        <thead>
          <tr>
            <th className="user_name_td">ПІБ</th>
            <th className="user_comment_td"></th>
            <th className="user_counter_num_td">
              <div className="counters">
                <span>Лічильник</span>
                <div className="filters">
                  <img
                    className={`${type === 'ASC' ? "active" : ""}`}
                    src={ImgBuilder.topFilter} alt=""
                    onClick={() => changeFilter('type', 'ASC')}
                  />
                  <img
                    className={`${type === 'DESC' ? "active" : ""}`}
                    src={ImgBuilder.downFilter} alt=""
                    onClick={() => changeFilter('type', 'DESC')}
                  />
                </div>
              </div>
            </th>
            <th className="user_quarter_td">Квартал</th>
            <th className="user_email_td">Електронна пошта</th>
            <th className="user_sent_reminder_td"></th>
            <th className="user_phone_number">Номер телефону</th>
            <th className="user_is_paid_td">Стан</th>
            <th className="user_status_td">Статус</th>
            <th className="user_menu_td"></th>
          </tr>
        </thead>
        <tbody>
          {users.map((item, key) => (
            <tr key={key}>
              {item.awaiting_confirmation ?
                <td className="user_name_td">
                  <ConfirmChangesPersData user={item} newData={item.new_data}>
                    <p className="await_confirm">{item.last_name} {item.name} {item.middle_name}</p>
                  </ConfirmChangesPersData>
                </td>
                :
                <td className="user_name_td">{item.last_name} {item.name} {item.middle_name}</td>}
              <td className="user_comment_td">
                <img className={item.notification ? 'active_notif' : ''}
                  src={ImgBuilder.comentar}
                  alt="comment"
                  onClick={() => setClickedComentar(item.id)}
                />
                {clickedComentar && <Comment
                  comment={item.notification}
                  userId={item.id}
                  clickedId={clickedComentar}
                  close={() => setClickedComentar("")}
                />}
              </td>
              <td className="user_counter_num_td">{item.counter_number}</td>
              <td className="user_quarter_td">{item.quarter_name}</td>
              <td className="user_email_td">{item.email}</td>
              <td className="user_sent_reminder_td">
                <img src={ImgBuilder.mail} alt="" onClick={() => sendReminder(item.user_id)} />
              </td>
              <td className="user_phone_number">{item.phone_number}</td>
              <td className="user_is_paid_td">
                {item.debtor ?
                  <span className="status debtor">Борг {(item.debtor / 100).toFixed(2)} грн</span> :
                  <span className="status paid ">Проплачено</span>
                }
              </td>
              <td className="user_status_td">
                {item.approved ?
                  <span className="status paid">Підтверджений</span> :
                  <span className="status debtor ">Не підтверджений</span>
                }
              </td>
              <td className="user_menu_td">
                <ShowUserPopup id={item.id} >
                  <span className="show_user">Показати</span>
                </ShowUserPopup>
                <EditProfilePopup user={item} isAdmin={true}>
                  <span className="edit_user">Редагувати</span>
                </EditProfilePopup>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default inject('usersAdminModel')(observer(AdminDashboardList));
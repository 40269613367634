import React from 'react';
import Popup from 'reactjs-popup';

import BasicButton from '../../../res/BasicButton/BasicButton';

import './DeletePopup.scss';

const DeletePopup = ({ remove, children }) => {

  const removeObject = (close) => {
    remove();
    close();
  }

  return (
    <div className="delete-pop-up-wrapper">
      <Popup
        trigger={open => (children)}
        modal
        closeOnDocumentClick={true}
      >
        {close => (
          <>
            <div className="header">
              <h1 className="delete_title">Ви впевнені, що хочете це зробити?</h1>
            </div>

            <button className="btn_wrapper">
              <BasicButton onClick={() => removeObject(close)} label="Видалити" width="120px" />
              <div className="cancel_btn" onClick={close}>Скасувати</div>
            </button >
          </>
        )}
      </Popup >
    </div>
  )
}

export default DeletePopup;
import React from 'react';
import { useHistory } from "react-router-dom";
import { stack as Menu } from 'react-burger-menu';
import { inject, observer } from "mobx-react";

import items from '../../const/navigationItems';
import exit from './exit.svg';

import "./NavBar.scss";

const NavBar = (props) => {
    const {
        authModel: {
            user, logOut
        },
        adminModel: {
            inbox_counter
        }
    } = props;

    const history = useHistory();

    const isCurrentTab = (item) => {
        return window.location.pathname.includes(item.path);
    }

    return <>
        <div className="menu">
            <Menu right>
                {items[user.role].map((item, key) => (
                    <div key={key} className={`${isCurrentTab(item) ? "active" : ""}`} onClick={() => history.push(item.path)}>
                        {item.name}
                        {(inbox_counter && item.name === 'Питання') && <span className='inbox_questions'>{inbox_counter < 100 ? inbox_counter : "99+"}</span>}
                    </div>
                ))}
                <div className="nav_item exit" onClick={logOut}>
                    <img src={exit} alt="" />
                </div>
            </Menu>
        </div>

        <div className="navbar">
            <div className="navbar-wrapper">
                <div className="navbar_items">
                    {items[user.role].map((item, key) => (
                        <div key={key} className={`nav_item ${isCurrentTab(item) ? "active" : ""}`} onClick={() => history.push(item.path)}>
                            {item.name}
                            {item.name === 'Питання' ? <span className='inbox_questions'>{inbox_counter < 100 ? inbox_counter : "99+"}</span> : null}
                        </div>
                    ))}
                </div>
                <div className="nav_item exit" onClick={logOut}>
                    <img src={exit} alt="" />
                </div>
            </div>
        </div>
    </>
}

export default inject('authModel', 'adminModel')(observer(NavBar));

import React from 'react';

import ShowUserAgreenment from '../../components/popups/ShowUserAgreenment/ShowUserAgreenment';

import './Instructions.scss';

const Instructions = () => {
    return (
        <div className="instructions_wrapper">
            <div className="instructions">
                <h1 className="title">Інструкції</h1>

                <div className="info">
                    <h1 className="subtitle">Що робити, якщо лічильник вийшов з ладу?</h1>
                    <h1 className="desc">
                        Якщо лічильник викрали або він перестав
                        працювати як належить, споживачу необхідно звернутися
                        у центр обслуговування клієнтів компанії. Якщо пошкодження сталось з вини власника лічильника,
                        всі витрати покладаються на власника засобів обліку, а якщо з вини постачальника електроенергії, то за все заплатить постачальник.
                        За період, коли лічильник не працював або некоректно обліковував спожиту електричну енергію, споживач платитиме по середньому добовому
                        споживанню.
                    </h1>
                </div>

                <div className="info">
                    <h1 className="subtitle">У яких випадках представник “Козацького” може потребувати доступу до приладів обліку електричної енергії мешканців?</h1>
                    <h1 className="desc">
                        Як правило, енергетикам потрібен доступ до лічильника для проведення технічної перевірки засобів вимірювання,
                        контролю за рівнем споживання електричної енергії та потужності, вимірювання показників якості електричної енергії, контрольного огляду
                        електричних мереж, здійснення заміни приладів обліку, для того, щоб взяти лічильник на повірку, або також для виконання відключення
                        та обмеження споживання. Цими представниками є електромонтери, інспектори або контролери, у яких є допуск до приладів обліку.
                    </h1>
                </div>

                <div className="info">
                    <h1 className="subtitle">Подання показників:</h1>
                    <h1 className="desc">
                        Показники лічильника спожитої електроенергії слід повідомляти щомісяця, що починається за 3 (три) календарні дні до кінця розрахункового місяця та закінчується останнім днем розрахункового місяця в особистому кабінеті користувача.
                        У випадку, якщо користувач не повідомить дані електролічильника у вказаний період, фактичний обсяг споживання електричної енергії за місяць визначатиметься розрахунковим шляхом за значенням середньодобового обсягу споживання користувача.
                    </h1>
                </div>

                <div className="info">
                    <h1 className="subtitle">Розрахунки за спожиту електроенергію:</h1>
                    <h1 className="desc"> - В призначенні платежу вказуємо: П.І.П, вул., номер лічильника.</h1>
                    <h1 className="desc"> - Підтвердженням проведеної Вами оплати за спожиту електроенергію є квитанція про оплату.</h1>
                    <h1 className="desc"> - Сплатити за спожиту електроенергію потрібно до 5 (п’ятого) числа, наступного місяця, що йде за розрахунковим.</h1>
                    <h1 className="desc"> - У разі несвоєчасної сплати буде нараховуватись пеня у розмірі подвійної облікової ставки НБУ за кожен день прострочення.</h1>
                </div>

                <div className="info">
                    <h1 className="subtitle">Розрахунок онлайн:</h1>
                    <h1 className="desc"> 1. Зайдіть в «Персональний кабінет»; </h1>
                    <h1 className="desc"> 2. В правій частині Ви побачите кнопку «Оплатити»;</h1>
                    <h1 className="desc"> 3. З’явиться вікно «Історія оплат» та сума, яку потрібно оплатити; </h1>
                    <h1 className="desc"> 4. Натисніть на кнопку «Оплатити» після чого з’явиться повідомлення про те, що рахунок надіслано Вам на електронну пошту; </h1>
                    <h1 className="desc">5. Перейдіть у свою електронну пошту відкрийте лист від «noreply@liqpay.ua» та натисніть кнопку «Оплатити рахунок»; </h1>
                    <h1 className="desc">6. Ви можете здійснити оплату через Приват24 або з картки іншого банку (введіть дані Вашої платіжної картки та здійсніть оплату). </h1>
                </div>

                <div className="info">
                    <h1 className="subtitle">Розрахунок за реквізитами:</h1>
                    <h1 className="desc">Отримувач: Квартальний комітет «Козацький» </h1>
                    <h1 className="desc">р/р UA80 305299 000002 600402 101028 3</h1>
                    <h1 className="desc">ЄДРПОУ 39488027</h1>
                </div>

                <div className="info">
                    <h1 className="subtitle">Договір споживача про постачання електричної енергії:</h1>
                    <ShowUserAgreenment>
                        <span className="desc show_agreenment">Показати</span>
                    </ShowUserAgreenment>
                </div>
            </div>
        </div>
    )
}

export default Instructions;
import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { Formik } from "formik";
import * as Yup from 'yup';

import BasicButton from '../../../res/BasicButton/BasicButton';
import ShowAttachment from '../ShowAttachment/ShowAttachment';

import './style.scss'

const AddAnswer = ({ attachment, question, children, addAnswer, id, status }) => {
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  return (
    <>
      <div className="add_answer_popup_wrapper">
        <Popup
          modal
          trigger={children}
          overlayStyle={{ background: 'rgba(0,0,0,0.5)' }}
          closeOnDocumentClick={true}
        >
          {close => (
            <div className="add_answer">
              <h3 className="add_answer_title">Питання:</h3>
              <p className="add_answer_question">{question}</p>
              {attachment && <div className="add_answer_attachment">
                <p className="attachment_added">Додано файл: <span onClick={() => setIsOpenPopup(true)} className='attachment_open'>Відкрити</span></p>
              </div>}
              <Formik
                initialValues={{
                  answer: '',
                }}
                validationSchema={Yup.object().shape({
                  answer: Yup.string().required('Обов\'язкове поле'),
                })}
                onSubmit={formValues => addAnswer(id, formValues.answer, close)}
              >
                {({ values, errors, handleChange, handleSubmit }) => (
                  <div className='form'>
                    <p className="form_item">
                      <textarea
                        className='textarea'
                        type="text"
                        placeholder="Введіть відповідь"
                        name="answer"
                        value={values.answer}
                        onChange={handleChange} />
                      <span className="error_text">{errors.answer ? errors.answer : null}</span>
                    </p>
                    <div className="btn_wrapper">
                      <BasicButton disabled={status === 'answered'} onClick={handleSubmit} label="Відповісти" width='156px' />
                    </div>
                  </div>
                )}
              </Formik>
            </div>
          )}
        </Popup >
      </div>
      {isOpenPopup && <ShowAttachment isOpen={isOpenPopup} setOpen={setIsOpenPopup} attachment={attachment} />}
    </>
  )
}

export default AddAnswer;

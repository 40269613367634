import React, { useState } from 'react';
import { inject, observer } from "mobx-react";
import { Formik } from 'formik';
import * as Yup from 'yup';

import ApiService from '../../../service/ApiService';
import BasicButton from '../../../res/BasicButton/BasicButton';
import ImgBuilder from '../../../img/ImgBuilder';
import DeletePopup from '../../../components/popups/DeletePopup/DeletePopup';
import ConfirmEditQuarterPopup from '../../../components/popups/ConfirmEditQuarterPopup/ConfirmEditQuarterPopup';

import './style.scss';

const SettingsQuarters = ({ adminModel: { quarters, refreshAdmin } }) => {
  const [isAddedQuarter, setIsAddedQuarter] = useState(false);

  const updateQuarters = (data) => {
    ApiService.updateQuarters(data).then(() => {
      setIsAddedQuarter(true);
      refreshAdmin();
    }).catch(() => {
      setIsAddedQuarter(false);
    })
  };

  const removeQuarter = (id) => {
    ApiService.removeQuarters(id).then(() => {
      refreshAdmin();
    }).catch(() => alert('Щось пішло не так.'))
  }

  return (
    <div className='quarter'>
      <Formik
        initialValues={{
          quarter: '',
        }}
        validationSchema={Yup.object().shape({
          quarter: Yup.string().required('Обов\'язкове поле'),
        })}
        onSubmit={(data) => updateQuarters({
          quarter: {
            name: data.quarter.toUpperCase()
          }
        })}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <div className='form'>
            <p className="form_item">
              <h3 className="label">Додати квартал:</h3>
              <input
                className={`input_quarters ${errors.quarter ? 'error' : null}`}
                type="text"
                placeholder="Введіть назву кварталу"
                name="quarter"
                value={values.quarter}
                onChange={handleChange} />
              <p className="error_text">{errors.quarter ? errors.quarter : null}</p>
            </p>
            {isAddedQuarter && <p className="saved">Квартал додано</p>}
            <div className="btn_wrapper">
              <BasicButton onClick={handleSubmit} label="Додати" width='156px' />
            </div>
          </div>
        )}
      </Formik>
      <div className="available_quarter">
        <h3 className="available_quarter_title">Наявні квартали:</h3>
        <div className="available_quarter_wrapper">
          {quarters.map((quarter) => (
            <div key={quarter.id} className="quarter_item">
              <ConfirmEditQuarterPopup quarter_name={quarter.name} id={quarter.id} refreshAdmin={refreshAdmin}>
                <img className="img_quarter_item" src={ImgBuilder.edit} alt="edit" />
              </ConfirmEditQuarterPopup>
              {quarter.name}
              <DeletePopup remove={() => removeQuarter(quarter.id)}>
                <img className="img_quarter_item" src={ImgBuilder.del} alt="delete" />
              </DeletePopup>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default inject('adminModel')(observer(SettingsQuarters));

import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Popup from 'reactjs-popup';

import ApiService from '../../../service/ApiService';

import BasicButton from '../../../res/BasicButton/BasicButton';

import './style.scss';

const ChangeEmail = ({ children }) => {
  const [serverError, setServerError] = useState();

  return (
    <div className="change-email-pop-up-wrapper">
      <Popup
        className="change_email_popup"
        trigger={children}
        modal
        closeOnDocumentClick={true}
        onClose={() => setServerError()}
      >
        {close => (
          <>
            <div className="change-email-header">
              <h1 className="title">Змінити електрону пошту</h1>
            </div>
            <Formik
              initialValues={{
                new_email: "",
                new_email_confirmation: "",
              }}
              validationSchema={Yup.object().shape({
                new_email: Yup.string().required("Обов'язкове поле").email("Введіть коректну ел.пошту").nullable(),
                new_email_confirmation: Yup.string().required("Обов'язкове поле")
                  .oneOf([Yup.ref('new_email'), null], 'Ел.пошта має співпадати').email("Введіть коректну ел.пошту").nullable(),
              })}
              validateOnBlur
              onSubmit={(data, {setSubmitting}) => {
                setSubmitting(true);
                ApiService.changeEmail({
                  user: { new_email: data.new_email }
                }).then((resp) => {
                  alert(resp.data.messages);
                  close();
                }).catch(({ response }) => {
                  if (response.data.errors[0] === 'Користувач з такою електронною адресою вже зареєстрований на сайті.') {
                    setServerError(response.data.errors[0]);
                  } else {
                    setServerError("Не вдалося змінити ел.пошту");
                  }
                }).finally(() => {
                  setSubmitting(false);
                })
              }}
            >
              {({ touched, errors, values, handleChange, handleSubmit, isSubmitting }) => (
                <Form>
                  <div className="form_item">
                    <h1 className="form_label">Нова ел.пошта</h1>
                    <input
                      type="text"
                      placeholder="Введіть нову ел.пошту"
                      className={`form_input ${errors.new_email && touched.new_email ? "error" : null}`}
                      name="new_email"
                      value={values.new_email}
                      onChange={handleChange} />
                    {(errors.new_email && touched.new_email) && <p className="error_text error_text_changeEmail">{errors.new_email}</p>}
                  </div>

                  <div className="form_item">
                    <h1 className="form_label">Підтвердіть ел.пошту</h1>
                    <input
                      type="text"
                      placeholder="Підтвердіть ел.пошту"
                      className={`form_input ${errors.new_email_confirmation && touched.new_email_confirmation ? "error" : null}`}
                      name="new_email_confirmation"
                      value={values.new_email_confirmation}
                      onChange={handleChange} />
                    {(errors.new_email_confirmation && touched.new_email_confirmation) && <p className="error_text error_text_changeEmail">{errors.new_email_confirmation}</p>}
                    {serverError && <p className="error_text error_text_changeEmail">{serverError}</p>}
                  </div>

                  <button className="btn_wrapper_center" disabled = {isSubmitting}>
                    <BasicButton label="Зберегти" width="125px" onClick={handleSubmit} disabled = {isSubmitting}/>
                    <div className="cancel" onClick={close}>Скасувати</div>
                  </button >
                </Form>
              )}
            </Formik>
          </>
        )}
      </Popup >
    </div>
  )
}

export default ChangeEmail;
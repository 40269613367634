import React, { useEffect } from 'react';
import { inject, observer } from "mobx-react";
// import Loader from "react-loader-spinner";

import AdminDashboardHeader from './header/AdminDashboardHeader';
import AdminDashboardList from './list/AdminDashboardList';
import Pagination from './pagination/Pagination';

import './AdminDashboard.scss';

const AdminDashboard = (props) => {
  const {
    usersAdminModel: {
      getAllUsers, pagination, refreshFilter,
      filter: {
        search, isDebtor, type,
      }
    }
  } = props;

  useEffect(() => {
    getAllUsers();
  }, [search, isDebtor, type, pagination.page]);

  useEffect(() => {
    return () => refreshFilter();
  }, []);

  // if (users.length === 0 && search === '') {
  //   return (
  //     <div className="loader_wrapper" style={{ width: "100%" }}>
  //       <Loader color="#007BB0" type='BallTriangle' width={150} height={150} />
  //     </div>
  //   )
  // }
  return (
    <div className="admin_dashboard_wrapper">
      <AdminDashboardHeader />
      <div className="pagination_container pagination_container_top">
        <Pagination pagination={pagination} getNextPage={(page) => getAllUsers(page)} />
      </div>
      <AdminDashboardList />
      <div className="pagination_container">
        <Pagination pagination={pagination} getNextPage={(page) => getAllUsers(page)} />
      </div>
    </div>
  )
}

export default inject('usersAdminModel')(observer(AdminDashboard));
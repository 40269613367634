import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Popup from 'reactjs-popup';
import { inject, observer } from "mobx-react";

import BasicButton from '../../../res/BasicButton/BasicButton';
import ApiService from '../../../service/ApiService';

import './EditCounter.scss';

const EditCounter = ({ children, refresh, counter, adminModel: { quarters } }) => {
    const [serverErrors, setServerErros] = useState(null);

    return (
        <div className="edit-counter-admin-pop-up-wrapper">
            <Popup
                trigger={open => (children)}
                modal
                closeOnDocumentClick={true}
                onClose={() => setServerErros(null)}
            >
                {close => (
                    <div className="content">
                        <Formik
                            initialValues={{
                                counter_number: counter.counter_number,
                                last_number: counter.last_number,
                                plomb_number: counter.plomb_number,
                                quarter_id: counter.quarter_id,
                            }}
                            validationSchema={Yup.object().shape({
                                counter_number: Yup.number()
                                    .required("Обов'язкове поле")
                                    .positive("Номер лічильника може бути лише цілим числом").typeError("Номер лічильника може бути лише цілим числом")
                                    .integer("Номер лічильника може бути лише цілим числом"),
                                last_number: Yup.number().typeError("Номер лічильника може бути лише цілим числом")
                                    .required("Обов'язкове поле")
                                    .positive("Номер пломби може бути лише цілим числом")
                                    .integer("Номер пломби може бути лише цілим числом"),
                                plomb_number: Yup.number().typeError("Номер лічильника може бути лише цілим числом")
                                    .required("Обов'язкове поле")
                                    .positive("Номер пломби може бути лише цілим числом")
                                    .integer("Номер пломби може бути лише цілим числом"),
                                quarter_id: Yup.string().required("Обов'язкове поле"),
                            })}
                            onSubmit={data => {
                                ApiService.editCounter(counter.id, {
                                    counter: {
                                        counter_number: data.counter_number,
                                        last_number: data.last_number,
                                        plomb_number: data.plomb_number,
                                        quarter_id: data.quarter_id,
                                    }
                                }).then(() => {
                                    setServerErros(null);
                                    refresh();
                                    close()
                                }).catch(err => {
                                    let errorData = err.response.data;
                                    if (errorData.errors[0] === 'Counter number has already been taken' && !counter.is_used) {
                                        setServerErros({ counter_number: "Такий номер лічильника вже зареєстровано" });
                                    } else {
                                        setServerErros({ counter_number: "Не можна змінити номер лічильника зареєстрованому користувачу" });
                                    }
                                })
                            }}
                        >
                            {({ errors, values, handleChange, setFieldValue }) => (
                                <Form>
                                    <h1 className="title">Редагувати лічильник</h1>
                                    <div className="form_item">
                                        <h1 className="form_label">Номер лічильника</h1>
                                        <input
                                            type="text"
                                            placeholder="Введіть номер"
                                            className={`form_input ${errors.counter_number ? "error" : null}`}
                                            name="counter_number"
                                            onChange={(e) => setFieldValue("counter_number", e.target.value)}
                                            value={values.counter_number} />
                                        <h1 className="error_text">{errors.counter_number ? errors.counter_number : ""}</h1>
                                        <h1 className="error_text">{serverErrors && serverErrors.counter_number && !errors.counter_number ? serverErrors.counter_number : ""}</h1>
                                    </div>
                                    <div className="form_item">
                                        <h1 className="form_label">Квартал</h1>
                                        <select
                                            className={`form_input ${errors.quarter_id ? "error" : null}`}
                                            name="quarter_id"
                                            onChange={(e) => setFieldValue("quarter_id", e.target.value)}
                                            value={values.quarter_id}>
                                            <option value={null} disabled selected>Оберіть квартал</option>
                                            {quarters.map((quarter) => (
                                                <option key={quarter.id} value={quarter.id}>{quarter.name}</option>
                                            ))}
                                        </select>
                                        <h1 className="error_text">{errors.quarter_id ? errors.quarter_id : ""}</h1>
                                    </div>

                                    <div className="form_item">
                                        <h1 className="form_label">Початковий показник</h1>
                                        <input
                                            type="text"
                                            placeholder="Введіть початковий показник"
                                            className={`form_input ${errors.last_number ? "error" : null}`}
                                            name="last_number"
                                            value={values.last_number}
                                            onChange={handleChange} />
                                        <h1 className="error_text">{errors.last_number ? errors.last_number : ""}</h1>
                                    </div>

                                    <div className="form_item">
                                        <h1 className="form_label">Номер пломби</h1>
                                        <input
                                            type="text"
                                            placeholder="Введіть номер пломби"
                                            className={`form_input ${errors.plomb_number ? "error" : null}`}
                                            name="plomb_number"
                                            value={values.plomb_number}
                                            onChange={handleChange} />
                                        <h1 className="error_text">{errors.plomb_number ? errors.plomb_number : ""}</h1>
                                        <h1 className="error_text">{serverErrors && serverErrors.plomb_number && !errors.plomb_number ? serverErrors.plomb_number : ""}</h1>
                                    </div>

                                    <button className="btn_wrapper" onClick={null}>
                                        <BasicButton label="Редагувати" width="156px" />
                                    </button >
                                </Form>
                            )}
                        </Formik>
                    </div>
                )}
            </Popup >
        </div>
    )
}

export default inject('adminModel')(observer(EditCounter));
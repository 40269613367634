import { makeAutoObservable, runInAction } from "mobx";

import ApiService from '../service/ApiService';

export class UserModel {
    constructor() {
        makeAutoObservable(this);
    }

    profile = {};
    settings = {};
    results = [];
    payedBills = [];
    unpayedBills = [];
    lastResult = {};

    getProfile = (fn_redirect) => {
        ApiService.getUserProfile().then(resp => {
            runInAction(() => this.profile = resp.data.profile)
            if (!this.profile.registration_finished) {
                fn_redirect();
            }
        });
    }

    updateProfile = (id, info, isAdmin, close, getUsers) => {
        ApiService.updateUserProfile(id, info, isAdmin).then(() => {
            isAdmin ? getUsers() : this.getProfile();
            close();
        });
    }

    getSettings = () => {
        ApiService.getPrice().then(resp => {
            runInAction(() => this.settings = resp.data)
        });
    }

    getResults = () => {
        ApiService.getAllResults().then(resp => {
            runInAction(() => this.results = resp.data)
        });
    }

    getLastResult = () => {
        ApiService.getLastResult().then(resp => {
            let firstNumber = resp.data.first_number ? resp.data.first_number.toString() : "000000";
            let lastNumber = resp.data.last_number ? resp.data.last_number.toString() : "00";

            const fixFirstNumber = (firstNumber) => {
                const numberLength = firstNumber.length;
                const requiredLength = 6
                if (numberLength < requiredLength) {
                    let result = "";
                    for (let charCounter = 0; charCounter < requiredLength - numberLength; charCounter++) {
                        result += "0"
                    }
                    return result + firstNumber;
                }
                return firstNumber
            }

            const fixedFirstNumber = fixFirstNumber(firstNumber);

            runInAction(() => {
                this.lastResult = {
                    one: fixedFirstNumber.charAt(0),
                    two: fixedFirstNumber.charAt(1),
                    three: fixedFirstNumber.charAt(2),
                    four: fixedFirstNumber.charAt(3),
                    five: fixedFirstNumber.charAt(4),
                    six: fixedFirstNumber.charAt(5),
                    seven: lastNumber.charAt(0),
                    eight: lastNumber.charAt(1)
                }
            })
        })
    }

    updateResult = (data) => {
        runInAction(() => this.lastResult = data);
    }

    getPayedBills = (params) => {
        ApiService.getBills(params).then(resp => {
            runInAction(() => this.payedBills = resp.data)
        });
    }

    getUnpayedBills = (params) => {
        ApiService.getBills(params).then(resp => {
            runInAction(() => this.unpayedBills = resp.data)
        });
    }

    refreshUser = (fn_redirect) => {
        this.getProfile(fn_redirect);
        this.getSettings();
        this.getResults();
        this.getPayedBills('true');
        this.getUnpayedBills('false');
        this.getLastResult();
    }
}

export const userModel = new UserModel();
const items = {
    user: [{
        name: "Персональний кабінет",
        path: "/dashboard",
        role: "user"
    },
    {
        name: "Зворотній зв’язок",
        path: "/feedback",
        role: "user"
    },
    {
        name: "Інструкції",
        path: "/instructions",
        role: "user"
    }, {
        name: "Контакти",
        path: "/contacts",
        role: "user"
    },
    {
        name: "Про нас",
        path: "/about_us",
        role: "user"
    },
    ],
    admin: [{
        name: "Користувачі",
        path: "dashboard",
        role: "admin"
    },
    {
        name: "Лічильники",
        path: "/counters",
        role: "admin"
    },
    {
        name: "Налаштування",
        path: "/settings",
        role: "admin"
    },
    {
        name: "Питання",
        path: "/questions",
        role: "admin"
    },
    ],
}

export default items;
import { computed, makeAutoObservable, runInAction } from "mobx";

import ApiService from '../service/ApiService';

export class AuthModel {
    constructor() {
        makeAutoObservable(this, {
            sessionToken: computed,
            user: computed,
        });
    };

    get sessionToken() {
        return JSON.parse(localStorage?.getItem('token'));
    };

    get user() {
        return JSON.parse(localStorage.getItem('user'));
    };

    serverError = null;
    refreshServerError = () => this.serverError = null;

    signIn = (data) => {
        ApiService.signIn(data).then(resp => {
            this.setLocalStorage(resp.data.data.user, resp.data.data.user.authentication_token);
            window.location.replace("/dashboard");
            if (this.serverError.signIn) {
                this.refreshServerError()
            }
        }).catch(error => {
            if (error.response) {
                runInAction(() => this.serverError = { signIn: 'Неправильний логін або пароль' });
            }
        })
    }

    signUp = (fields) => {
        ApiService.signUp({
            user: {
                email: fields.email, password: fields.password,
                password_confirmation: fields.confirmPassword, counter_number: fields.counter_id
            }
        }).then(resp => {
            this.setLocalStorage(resp.data.data.user, resp.data.data.user.authentication_token);
            window.location.replace("/personal_info");
            if (this.serverError) {
                this.refreshServerError()
            }
        }).catch((error) => {
            if (error.response.data.errors === "Counter number is wrong") {
                runInAction(() => this.serverError = { counter: "Невірний номер лічильника" });
            } else if (error.response.data.email) {
                runInAction(() => this.serverError = { email: "Такий емейл вже зареєстровано!" });
            } else if (error.response) {
                runInAction(() => this.serverError = { counter: "Такий номер лічильника вже зареєстровано" });
            }
        })
    }

    logOut = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        window.location.replace("/sign_in");
    }

    setLocalStorage = (user, token) => {
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", JSON.stringify(token))
    }
}

export const authModel = new AuthModel();
import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';

import ApiService from '../../../service/ApiService';

import './EditResult.scss';

const EditResult = ({ user, children, refresh, closeParrent, lastResultFirstNumber, lastResultLastNumber }) => {
    const [numbers, updateNumbers] = useState({});
    const [error, setError] = useState(false);
    useEffect(() => {
        getLastResult();
    }, []);

    const blockScroll = () => {
        if (document.activeElement.type === "number") {
            document.activeElement.blur();
        };
    };

    useEffect(() => {
        document.addEventListener("wheel", blockScroll);
        return () => document.removeEventListener("wheel", blockScroll)
    })

    const getLastResult = () => {
        let firstNumber = lastResultFirstNumber ? lastResultFirstNumber.toString() : "000000";
        let lastNumber = lastResultLastNumber ? lastResultLastNumber.toString() : "00";
        let fixedFirstNumber = fixFirstNumber(firstNumber);
        updateNumbers({
            one: fixedFirstNumber.charAt(0),
            two: fixedFirstNumber.charAt(1),
            three: fixedFirstNumber.charAt(2),
            four: fixedFirstNumber.charAt(3),
            five: fixedFirstNumber.charAt(4),
            six: fixedFirstNumber.charAt(5),
            seven: lastNumber.charAt(0),
            eight: lastNumber.charAt(1),
        });
    }

    const fixFirstNumber = (firstNumber) => {
        const numberLength = firstNumber.length;
        const requiredLength = 6
        if (numberLength < requiredLength) {
            let result = "";
            for (let charCounter = 0; charCounter < requiredLength - numberLength; charCounter++) {
                result += "0"
            }
            return result + firstNumber;
        }
        return firstNumber
    }

    const handleChange = (e) => {
        let newValue = e.target.value;
        let lastInputChar = getDifference(numbers[e.target.name], newValue);
        if (lastInputChar) {
            updateNumbers({ ...numbers, [e.target.name]: lastInputChar });
            focusNextInput(e.target.id);
        }
    }

    // 8 is amount of inputs from 0 [0,1,2,3,4,5,6,7]
    const focusNextInput = (inputId) => {
        let currentInputInt = parseInt(inputId);
        let nextInputId = (currentInputInt + 1).toString();
        if (currentInputInt === 7) {
            document.getElementById(0).focus();
        } else {
            document.getElementById(nextInputId).focus();
        }
    }

    const focusPrevInput = (inputId) => {
        let currentInputInt = parseInt(inputId);
        let prevInputId = (currentInputInt - 1).toString();
        if (currentInputInt === 0) {
            document.getElementById(7).focus();
        } else {
            document.getElementById(prevInputId).focus();
        }
    }

    const onKeyDown = (e) => {
        if (e.keyCode === 8) {
            updateNumbers({ ...numbers, [e.target.name]: "0" });
            focusPrevInput(e.target.id)
        }
    }

    const saveData = (close) => {
        ApiService.editCounterResult(user.id, {
            result: {
                first_number: `${numbers.one}${numbers.two}${numbers.three}${numbers.four}${numbers.five}${numbers.six}`,
                last_number: `${numbers.seven}${numbers.eight}`
            }
        }).then(() => {
            refresh();
            close();
            closeParrent();
        }).catch(() => {
            setError("Показник має бути більшим ніж попереднього разу!");
        })
    }

    return (
        <div className="edit-result-pop-up-wrapper">
            <Popup
                className="edit_result_popup"
                trigger={open => (children)}
                modal
                closeOnDocumentClick={true}
                onClose={() => {
                    setError(false);
                    getLastResult();
                }}
            >
                {close => (
                    <>
                        <div className="add-result-pop-up-header">
                        </div>

                        <div className="counter">
                            <h1 className="text _12">Лічильник</h1>
                            <h1 className="text _14">№{user.counter_number}</h1>
                            <h1 className="text _18" >Поточні покази</h1>
                            <div className="numbers">
                                <input
                                    className="number one"
                                    type="number"
                                    placeholder="0"
                                    id="0"
                                    value={numbers.one}
                                    name="one"
                                    onKeyDown={onKeyDown}
                                    onChange={e => handleChange(e)}
                                />

                                <input
                                    className="number two"
                                    type="number"
                                    placeholder="0"
                                    id="1"
                                    value={numbers.two}
                                    name="two"
                                    onKeyDown={onKeyDown}
                                    onChange={e => handleChange(e)}
                                />

                                <input
                                    className="number three"
                                    type="number"
                                    placeholder="0"
                                    id="2"
                                    value={numbers.three}
                                    name="three"
                                    onKeyDown={onKeyDown}
                                    onChange={e => handleChange(e)}
                                />

                                <input
                                    className="number four"
                                    type="number"
                                    placeholder="0"
                                    value={numbers.four}
                                    name="four"
                                    id="3"
                                    onKeyDown={onKeyDown}
                                    onChange={e => handleChange(e)}
                                />

                                <input
                                    className="number four"
                                    type="number"
                                    placeholder="0"
                                    value={numbers.five}
                                    name="five"
                                    id="4"
                                    onKeyDown={onKeyDown}
                                    onChange={e => handleChange(e)}
                                />

                                <input
                                    className="number four"
                                    type="number"
                                    placeholder="0"
                                    value={numbers.six}
                                    name="six"
                                    id="5"
                                    onKeyDown={onKeyDown}
                                    onChange={e => handleChange(e)}
                                />

                                <input
                                    className="number six"
                                    type="number"
                                    placeholder="0"
                                    value={numbers.seven}
                                    name="seven"
                                    id="6"
                                    onKeyDown={onKeyDown}
                                    onChange={e => handleChange(e)}
                                />
                                <input
                                    className="number six"
                                    type="number"
                                    id="7"
                                    placeholder="0"
                                    value={numbers.eight}
                                    name="eight"
                                    onKeyDown={onKeyDown}
                                    onChange={e => handleChange(e)}
                                />
                            </div>
                            {error ? <div className="error">{error}</div> : ""}

                            <div className="save" onClick={() => saveData(close)}>
                                Подати покази
                            </div>
                            <div className="cancel" onClick={close}>Скасувати</div>
                        </div>
                    </>
                )}
            </Popup >
        </div>
    )
}

function getDifference(a, b) {
    var i = 0;
    var j = 0;
    var result = "";

    while (j < b.length) {
        if (a[i] !== b[j] || i === a.length)
            result += b[j];
        else
            i++;
        j++;
    }
    return result;
}

export default EditResult;
import React from 'react';
import { inject, observer } from "mobx-react";
import {gavnoCode} from '../../../../const/getLastPayment';
import BasicListItem from '../../../../res/BasicListItem/BasicListItem';
import ShowAllBills from '../../../../components/popups/ShowAllBills/ShowAllBills';

import './PayData.scss'

const PayData = ({ userModel: { payedBills, profile } }) => {


    return <div className="pay-data">
        <div className="header">
            <span className="title">Останні платежі</span>
        </div>
        <div className="content">
            <div className="content_header">
                <span>Дата</span>
                <span>Повна вартість</span>
                <span>Зараховано(грн)</span>
                
            </div>
           

            <div className="list">
                {/* //map array in reverse order */}
                {payedBills.slice(0).reverse().map((item, key) => {
                    if (key < 3) {
                        return gavnoCode(item);
                    } return null
                })}
                <i style = {{marginTop: '8px'}}>{profile.prepayment !== 0 ? `(Предоплата: ${(profile.prepayment / 100).toFixed(2)})` : ''}</i>

            </div>

            <div className="content_footer">
                <ShowAllBills bills={payedBills} profile = {profile} >
                    <span className="show_all">Переглянути всі</span>
                </ShowAllBills>
            </div>
        </div>
    </div>
}

export default inject('userModel')(observer(PayData));
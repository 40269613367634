import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Popup from 'reactjs-popup';

import ApiService from '../../../service/ApiService';

import BasicButton from '../../../res/BasicButton/BasicButton';
import ImgBuilder from '../../../img/ImgBuilder';

import './ChangePassword.scss';

const ChangePassword = ({ children }) => {
    const [showPassword, toggleShowPassword] = useState(false);
    const [serverError, setServerError] = useState();
    return (
        <div className="change-password-pop-up-wrapper">
            <Popup
                className="change_password_popup"
                trigger={open => (children)}
                modal
                closeOnDocumentClick={true}
            >
                {close => (
                    <>
                        <div className="change-password-header">
                            <h1 className="title">Змінити пароль</h1>
                        </div>
                        <Formik
                            initialValues={{
                                password: "",
                                password_confirmation: "",
                                current_password: ""
                            }}
                            validationSchema={Yup.object().shape({
                                password: Yup.string().required("Обов'язкове поле").nullable(),
                                password_confirmation: Yup.string().required("Обов'язкове поле")
                                    .oneOf([Yup.ref('password'), null], 'Паролі мають співпадати').nullable(),
                                current_password: Yup.string().required("Обов'язкове поле").nullable(),
                            })}
                            onSubmit={user => {
                                ApiService.changePassword({ user }).then(() => {
                                    alert("Пароль змінено")
                                    close()
                                }).catch(() => {
                                    setServerError("Невірний пароль");
                                })
                            }}
                        >
                            {({ errors, touched, values, handleChange }) => (
                                <Form>
                                    <div className="form_item">
                                        <h1 className="form_label">Старий пароль</h1>
                                        <img className="viewPassword_changepassword" src={ImgBuilder.eye} alt="" onClick={() => toggleShowPassword((prevState) => !prevState)} />
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            placeholder="Введіть старий пароль"
                                            className={`form_input ${errors.current_password ? "error" : null}`}
                                            name="current_password"
                                            value={values.current_password}
                                            onChange={handleChange} />
                                        {(errors.current_password && touched.current_password) && <h1 className="error_text">{errors.current_password}</h1>}
                                        {(!errors.current_password && serverError) && <h1 className="error_text">{serverError}</h1>}
                                    </div>

                                    <div className="form_item">
                                        <h1 className="form_label">Новий пароль</h1>
                                        <img className="viewPassword_changepassword" src={ImgBuilder.eye} alt="" onClick={() => toggleShowPassword((prevState) => !prevState)} />
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            placeholder="Введіть новий пароль"
                                            className={`form_input ${errors.password ? "error" : null}`}
                                            name="password"
                                            value={values.password}
                                            onChange={handleChange} />
                                        {(errors.password && touched.password) && <h1 className="error_text">{errors.password}</h1>}
                                    </div>

                                    <div className="form_item">
                                        <h1 className="form_label">Підтвердити пароль</h1>
                                        <img className="viewPassword_changepassword" src={ImgBuilder.eye} alt="" onClick={() => toggleShowPassword((prevState) => !prevState)} />
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            placeholder="Підтвердіть пароль"
                                            className={`form_input ${errors.password_confirmation ? "error" : null}`}
                                            name="password_confirmation"
                                            value={values.password_confirmation}
                                            onChange={handleChange} />
                                        {(errors.password_confirmation && touched.password_confirmation) && <h1 className="error_text">{errors.password_confirmation}</h1>}
                                    </div>

                                    <button className="btn_wrapper_center" onClick={null}>
                                        <BasicButton label="Зберегти" width="125px" />
                                        <div className="cancel" onClick={close}>Скасувати</div>
                                    </button >
                                </Form>
                            )}
                        </Formik>
                    </>
                )}
            </Popup >
        </div>
    )
}


export default ChangePassword;
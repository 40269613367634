import React from 'react';
import Popup from 'reactjs-popup';
import { inject, observer } from "mobx-react";

import UserData from './UserData/UserData';
import BasicButton from '../../../res/BasicButton/BasicButton';
import ApiService from '../../../service/ApiService';

import './style.scss';

const ConfirmChangesPersData = ({ children, user, newData, usersAdminModel: { getAllUsers } }) => {

  const confirmChanges = (closePopup, status) => {
    ApiService.confirmChangesPersData(user.id, { status: status }).then(() => {
      getAllUsers();
      closePopup();
    })
  }

  return (
    <div className="confirm_change_popup">
      <Popup
        trigger={children}
        modal
        closeOnDocumentClick={true}
      >
        {close => (
          <div className="confirm_changes">
            <h3 className="confirm_changes_title">Користувач змінив свої персональні дані. Підтвердіть або скасуйте зміни.</h3>
            <div className="user_data_wrapper">
              <UserData title="Попередні дані:" user={user} />
              <UserData title="Нові дані:" user={newData} />
            </div>
            <div className="btn_wrapper">
              <BasicButton label="Підтвердити" width="130px" onClick={() => confirmChanges(close, "confirm")} />
              <BasicButton label="Скасувати" width="130px" onClick={() => confirmChanges(close, "notconfirm")} background="#778899" />
            </div>
          </div>
        )}
      </Popup >
    </div>
  )
};

export default inject('usersAdminModel')(observer(ConfirmChangesPersData));

import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import Loader from "react-loader-spinner";

import ApiService from '../../../service/ApiService';
import BasicButton from '../../../res/BasicButton/BasicButton';
import ImgBuilder from '../../../img/ImgBuilder';

import './style.scss';

const ShowAllAnswers = ({ isOpenPopup, closePopup }) => {
  const [isLoader, setIsLoader] = useState(true);
  const [dataQuestions, setDataQuestions] = useState([]);

  useEffect(() => {
    ApiService.getAllQuestions().then((resp) => {
      setDataQuestions(resp.data);
      setIsLoader(false);
    })
  }, [])

  return (
    <div className="show_all_answers_popup">
      <Popup
        open={isOpenPopup}
        modal
        closeOnDocumentClick={true}
        onClose={closePopup}
      >
        {close => (
          isLoader ? <div className="loader_wrapper">
            <Loader color="#007BB0" type='BallTriangle' width={150} height={150} />
          </div> :
            <div className="show_all_answers_wrapper">
              {dataQuestions.length === 0 ?
                <div className="no_answer_block">
                  <span className="no_answer">
                    Ви не задали жодного питання.
                </span>
                </div> :
                <div className="question_block">
                  {dataQuestions.reverse().map(({ id, question, status, answer }) => (
                    <details key={id} className="details_answer">
                      <summary className="summary_question">
                        {status === 'answered' ?
                          <img className="img_status" src={ImgBuilder.ok} alt="OK" /> :
                          <img className="img_status" src={ImgBuilder.hourglass} alt="Waiting" />
                        }
                        {question}
                      </summary>
                      {status === 'answered' ?
                        <span>{answer}</span> :
                        <span className="not_answer">
                          Очікуйте на відповідь від адміністратора.
                        </span>
                      }
                    </details>
                  ))}
                </div>
              }
              <div className="btn_wrapper">
                <BasicButton label="Закрити" background="#778899" width="156px" onClick={close} />
              </div>
            </div>
        )}
      </Popup >
    </div>
  )
}

export default ShowAllAnswers;

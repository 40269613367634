import React from 'react';
import { inject, observer } from "mobx-react";
import Loader from "react-loader-spinner";

import PersonalData from './personalData/PersonalData';
import BillingData from './billing/BillingData';
import CounterData from './counter/CounterData';
import PayData from './pays/PayData';

import './UserDashboard.scss';

const UserDashboard = ({ userModel: { profile } }) => {
    if (Object.keys(profile).length === 0) {
        return (
            <div className="loader_wrapper">
                <Loader color="#007BB0" type='BallTriangle' width={150} height={150} />
            </div>
        )
    }
    return (
        <div className="user_dashboard_wrapper">
            <h1 className="user_dashboard_wrapper_title">Вітаємо, {profile.last_name} {profile.name} {profile.middle_name}</h1>
            <div className="content">
                <div className="blocks_line">
                    <PersonalData />
                    <BillingData />
                </div>
                <div className="blocks_line">
                    <CounterData />
                    <PayData />
                </div>
            </div>
        </div>
    )
}

export default inject('userModel')(observer(UserDashboard));
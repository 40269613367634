import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import Popup from 'reactjs-popup';

import pdf from "./aggreement.pdf";
import ImgBuilder from '../../../img/ImgBuilder';

import './style.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ShowUserAgreenment = ({ children }) => {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div className="user_agrenment-pop-up-wrapper">
      <Popup
        trigger={children}
        modal
        closeOnDocumentClick={true}
      >
        {close => (
          <>
            <img src={ImgBuilder.close} className="close_popup" onClick={close} alt="close" />
            <div className="wrapper">
              <Document
                file={pdf}
                options={{ workerSrc: "/pdf.worker.js" }}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            </div>
          </>
        )}
      </Popup >
    </div>
  )
}

export default ShowUserAgreenment;
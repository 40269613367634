import React from 'react';

import './AboustUs.scss'

const AboutUs = () => {
    return <div className="about_us_wrapper">
        <div className="about_us">
            <h1 className="title_about">Про нас</h1>
            <h1 className="desc_about"> Персональний кабінет – це платформа онлайн-послуг, яка створена для зручності користувачів та сприяє спрощенню розрахунків за спожиту електроенергію.</h1>
            <h1 className="desc_about"> Тут в режимі онлайн Ви можете:</h1>
            <h3 className="desc_about subtitle_about"> - контролювати Ваші розрахунки за електропостачання;</h3>
            <h3 className="desc_about subtitle_about"> - вносити показники Вашого приладу обліку електроенергії;</h3>
            <h3 className="desc_about subtitle_about"> - оплати за спожиту електроенергію.</h3>
            <h1 className="desc_about">Якщо виникатимуть питання в процесі користування «Персональним кабінетом», Ви можете надіслати запит у «Зворотній зв'язок» і адміністрація обов'язково зв'яжеться з Вами.</h1>
        </div>
    </div>
}

export default AboutUs;
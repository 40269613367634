import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

import ImgBuilder from '../../img/ImgBuilder';
import HomeInfo from './Home/HomeInfo';
import Personal from './Personal/Personal';
import ApiService from '../../service/ApiService'

import './PersonalInfo.scss'

const PersonalInfo = () => {
  const history = useHistory();
  const [serverErrors, setServerErrors] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    last_name: "",
    middle_name: "",
    street: "",
    building_number: "",
    flat_number: "",
    phone_number: ""
  })

  const [currentBlock, setCurrentBlock] = useState("personal");

  const updateUserData = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value })
  }
  const changeBlock = (block) => {
    setCurrentBlock(block);
  }

  const handleSave = () => {
    ApiService.saveUserInfo(userData).then(() => {
      history.push('/dashboard')
    }).catch(error => {
      if (error.response) {
        setServerErrors(error.response.data)
      }
    })
  }

  return (
    <div className="personal_info_page">
      <div className="personal_info_wrapper">
        <div className="left_nav">
          <div className="item">
            {currentBlock === "home_info" ? <div className="succes"><img src={ImgBuilder.done} alt="done" /></div> : <div className="number">1</div>}
            <span className="text">Особиста інформація</span>
          </div>

          <div className={`item ${currentBlock === "personal" ? "grey" : ""} `}>
            <div className="number">2</div>
            <span className="text">Інформація про житло</span>
          </div>
        </div>

        <div className="personal_info_form">
          {currentBlock === "personal" ?
            <Personal
              changeBlock={(e) => changeBlock(e)}
              userData={userData}
              updateUserData={(e) => updateUserData(e)}
            /> :
            <HomeInfo
              changeBlock={(e) => changeBlock(e)}
              userData={userData}
              updateUserData={(e) => updateUserData(e)}
              handleSave={handleSave}
            />
          }
        </div>
      </div>
    </div>
  )
}

export default PersonalInfo;
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { inject, observer } from "mobx-react";

import Footer from '../../components/footer/Footer';
import NavBar from '../../components/navbar/NavBar';

import './style.scss';

const BasicPage = (props) => {
    const {
        children,
        userModel: {
            refreshUser
        },
        authModel: {
            user
        },
        adminModel: {
            refreshAdmin
        }
    } = props;

    const history = useHistory();
    const redirect = () => history.push("/personal_info");

    useEffect(() => {
        user.role === 'user' ? refreshUser(redirect) : refreshAdmin();
    }, [])

    return (
        <div className='basic-page'>
            <NavBar />
            {children}
            <Footer />
        </div>
    )
}

export default inject('userModel', 'authModel', 'adminModel')(observer(BasicPage));

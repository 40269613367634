import React from 'react';
import { inject, observer } from "mobx-react";
import moment from 'moment';

import BasicListItem from '../../../../res/BasicListItem/BasicListItem';
import BasicButton from '../../../../res/BasicButton/BasicButton';
import AddResult from '../../../../components/popups/AddResult/AddResult';
import ShowAllResults from '../../../../components/popups/ShowAllResults/ShowAllResults';

import './CounterData.scss';

const CounterData = ({ userModel: { profile, results } }) => {

    return <div className="counter-data">
        <div className="header">
            <span className="title">{`Лічильник №${profile.counter_number}`}</span>
        </div>
        <div className="content">
            <div className="content_header">
                <span>Дата</span>
                <span>Показник</span>
            </div>

            <div className="list">
                {/* //map array in reverse order */}
                {results.slice(0).reverse().map((item, key) => {
                    if (key < 3) {
                        return <BasicListItem key={item.id} label={moment(item.created_at).format("DD.MM.YYYY")} value={`${item.first_number},${item.last_number ? item.last_number : "00"}`} />
                    }
                })}
            </div>

            <div className="content_footer">
                <AddResult>
                    <BasicButton label="Внести покази" width="130px" disabled={!profile.confirmed || !profile.approved} />
                </AddResult>
                <ShowAllResults results={results}>
                    <span className="show_all">Переглянути всі</span>
                </ShowAllResults>
            </div>
        </div>
    </div>
}

export default inject('userModel')(observer(CounterData));
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import { inject } from "mobx-react";

import ProtectedRoute from './ProtectedRoutes';
import SignIn from '../pages/login/signIn/SignIn';
import SignUp from '../pages/login/signUp/SignUp';
import PersonalInfo from '../pages/personalInfo/PersonalInfo';
import Dashboard from '../pages/dashboard/Dashboard';
import Settings from '../pages/settings/Settings';
import Counters from '../pages/counters/Counters';
import Contacts from '../pages/contacts/Contacts';
import AboutUs from '../pages/about_us/AboutUs';
import Instructions from '../pages/instcructions/Instructions';
import Feedback from '../pages/feedback/Feedback';
import ForgotPassword from '../pages/login/forgotPassword/ForgotPassword';
import ResetPassword from '../pages/login/resetPassword/ResetPassword';
import Questions from '../pages/questions/Questions';
import ConfirmEmail from '../pages/confirm_email/ConfirmEmail';
import BasicPage from '../pages/BasicPage/BasicPage';
import BasicPageIntro from '../components/BasicPageIntro/BasicPageIntro';

import ApiService from '../service/ApiService';

const userIdleTime = 900000;
const adminIdleTime = 1800000;

const MainRouter = ({ authModel: { sessionToken, user, logOut } }) => {
  const idlTime = user && user.role === "user" ? userIdleTime : adminIdleTime;
  const [idleTimer, setIdleTimer] = useState(null);

  const history = useHistory();
  const redirect = () => history.push('/sign_in');

  const logOutTimer = () => {
    //loggout only if user is signed in , and not in video session
    if (sessionToken) {
      logOut(redirect);
    }
  };

  return <>
    <IdleTimer
      ref={ref => { setIdleTimer(ref) }}
      element={document}
      onIdle={logOutTimer}
      debounce={250}
      timeout={idlTime} />
    <Router>
      <Switch>
        <Route exact path="/sign_in" component={() => <BasicPageIntro><SignIn /></BasicPageIntro>} />
        <Route exact path="/sign_up" component={() => <BasicPageIntro><SignUp /></BasicPageIntro>} />
        <Route exact path="/forgot_password" component={() => <BasicPageIntro><ForgotPassword /></BasicPageIntro>} />
        <Route exact path="/sign_in/reset_password" component={() => <BasicPageIntro><ResetPassword /></BasicPageIntro>} />
        <Route exact path="/personal_info" component={() => <BasicPageIntro><PersonalInfo /></BasicPageIntro>} />
        <Route exact path="/confirm_change_old_email/:id" render={
          () => <BasicPageIntro><ConfirmEmail confirmEmail={(token) => ApiService.confirmChangeOldEmail(token)} /></BasicPageIntro>}
        />
        <Route exact path="/confirm_change_new_email/:id" render={
          () => <BasicPageIntro><ConfirmEmail confirmEmail={(token) => ApiService.confirmChangeNewEmail(token)} /></BasicPageIntro>}
        />
        <Route exact path="/confirm_email/:id" render={
          () => <BasicPageIntro><ConfirmEmail confirmEmail={(token) => ApiService.confirmEmail(token)} /></BasicPageIntro>}
        />

        <ProtectedRoute exact path="/dashboard" component={() => <BasicPage><Dashboard /></BasicPage>} />
        <ProtectedRoute exact path="/settings" component={() => <BasicPage><Settings /></BasicPage>} />
        <ProtectedRoute exact path="/counters" component={() => <BasicPage><Counters /></BasicPage>} />
        <ProtectedRoute exact path="/about_us" component={() => <BasicPage><AboutUs /></BasicPage>} />
        <ProtectedRoute exact path="/contacts" component={() => <BasicPage><Contacts /></BasicPage>} />
        <ProtectedRoute exact path="/instructions" component={() => <BasicPage><Instructions /></BasicPage>} />
        <ProtectedRoute exact path="/feedback" component={() => <BasicPage><Feedback /></BasicPage>} />
        <ProtectedRoute exact path="/questions" component={() => <BasicPage><Questions /></BasicPage>} />

        <Route exact path="/">
          <Redirect to="/dashboard" />
        </Route>
      </Switch>
    </Router>

  </>

};

export default inject('authModel')(MainRouter);

import React from 'react';
import moment from 'moment';

export const gavnoCode = (item) => {
    const date = moment(item.created_at).format("DD.MM.YYYY");
    const fullPrice = ((item.price + item.extra_price) / 100).toFixed(2);
    let zarahovano =  item.status === "unpaid" ? (item.part_price / 100).toFixed(2) : fullPrice;    
    return (
        <div className="list_items">
            <span>{date}</span>
            <span>{fullPrice}</span>
            <span>{zarahovano}</span>
        </div>
    )
}
import React from 'react';
import { inject, observer } from "mobx-react";
import moment from 'moment';
// import Loader from "react-loader-spinner";

import ApiService from '../../service/ApiService';
import Pagination from '../dashboard/AdminDashboard/pagination/Pagination';
import AddAnswer from '../../components/popups/AddAnswer/AddAnswer';

import './Questions.scss';

const Questions = (props) => {
  const {
    adminModel: {
      getQuestions, questions, questionsPagination,
    }
  } = props;

  const confirm = (id, answer, callback) => {
    ApiService.confrimQuestionAnswered(id, answer).then(() => {
      callback();
      getQuestions(questionsPagination.page);
    })
  }

  // if (questions.length === 0) {
  //   return (
  //     <div className="loader_wrapper">
  //       <Loader color="#007BB0" type='BallTriangle' width={150} height={150} />
  //     </div>
  //   )
  // }
  return (
    <div className="questions_wrapper">
      <div className="admin_dashboard_list">
        <div className="question_table">
          <table>
            <thead className="question_table_head">
              <tr>
                <th className="question_name">ПІБ</th>
                <th className="question_email">Пошта</th>
                <th className="question_phone">Телефон</th>
                <th className="question_td question_add_answer">Питання</th>
                <th className="question_date">Дата</th>
                <th className="question_status">Статус</th>
              </tr>
            </thead>
            <tbody>
              {questions.map((item, key) => (
                <tr key={key}>
                  <td className="question_td question_name">{item.name}</td>
                  <td className="question_td question_email">{item.email}</td>
                  <td className="question_td question_phone">{item.phone_number}</td>
                  <td className="question_td question_add_answer">
                    <AddAnswer
                      addAnswer={confirm}
                      id={item.id}
                      question={item.question}
                      attachment={item.attachment_url}
                      email={item.email}
                      status={item.status}
                    >
                      <p className="question">{item.question}</p>
                    </AddAnswer>
                  </td>
                  <td className="question_td question_date">{moment(item.created_at).format("DD.MM.YYYY, hh:mm")}</td>
                  <td className="question_td question_status">{item.status === "in_line"
                    ?
                    // <span className="answer" onClick={() => confirm(item.id)}>Підтвердити відповідь</span>
                    <span className="answer">Відповідь не надано</span>
                    : <span className="answered">Відповідь надано</span>}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="pagination_container">
          <Pagination pagination={questionsPagination} getNextPage={(e) => getQuestions(e)} />
        </div>
      </div>
    </div>
  )
}

export default inject('adminModel')(observer(Questions));

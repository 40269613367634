import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import BasicButton from '../../../res/BasicButton/BasicButton';

import './HomeInfo.scss';

const HomeInfo = ({ changeBlock, updateUserData, userData, handleSave }) => {

    return <div className="home_info_wrapp">
        <div className="form">
            <h1 className="title">Інформація про житло</h1>
            <Formik
                initialValues={userData}
                validateOnBlur
                validationSchema={Yup.object().shape({
                    street: Yup.string().required("Обов'язкове поле"),
                    building_number: Yup.string().required("Обов'язкове поле"),
                })}
                onSubmit={() => handleSave()}
            >
                {({ errors, touched, values, handleChange }) => (
                    <Form>
                        <h1 className="form_label">Адреса</h1>
                        <div className="line">
                            <div className="form_item">
                                <input
                                    type="text"
                                    placeholder="Вулиця"
                                    className={`form_input ${errors.street ? "error" : null}`}
                                    name="street"
                                    value={values.street}
                                    onChange={(e) => {
                                        updateUserData(e)
                                        handleChange(e)
                                    }} />
                                {(errors.street && touched.street) && <h1 className="error_text">{errors.street}</h1>}
                            </div>

                            <div className="form_item">
                                <input
                                    type="text"
                                    placeholder="Номер будинку"
                                    className={`form_input ${errors.building_number ? "error" : null}`}
                                    name="building_number"
                                    value={values.building_number}
                                    onChange={(e) => {
                                        updateUserData(e)
                                        handleChange(e)
                                    }} />
                                {(errors.building_number && touched.building_number) && <h1 className="error_text">{errors.building_number}</h1>}
                            </div>

                            <div className="form_item">
                                <input
                                    type="text"
                                    placeholder="Номер квартири"
                                    className={`form_input ${errors.flat_number ? "error" : null}`}
                                    name="flat_number"
                                    value={values.flat_number}
                                    onChange={(e) => {
                                        updateUserData(e)
                                        handleChange(e)
                                    }} />
                                {(errors.flat_number && touched.flat_number) && <h1 className="error_text">{errors.flat_number}</h1>}
                            </div>
                        </div>

                        <div className="button_wrapper">
                            <div className="back" onClick={() => changeBlock("personal")}>
                                <span>Назад</span>
                            </div>
                            <button className="submit" onClick={null}>
                                <BasicButton label="Далі" width="100px" />
                            </button >
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    </div>
}

export default HomeInfo;
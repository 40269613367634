import { makeAutoObservable, runInAction } from "mobx";

import ApiService from '../service/ApiService';

export class UsersAdminModel {
    constructor() {
        makeAutoObservable(this);
    };

    users = [];
    pagination = {
        page: 1,
        total_pages: 1,
        per_page: 10,
    };
    userData = {};
    isDataLoading = false;
    filter = {
        search: '',
        isDebtor: 'false',
        type: 'ASC',
        isApprowed: 'false',
        quarter: '',
        isQuarter: 'false',
        isAwaitingConfrim: 'false',
    };

    getAllUsers = (page) => {
        ApiService.getAllUsers(page ?? this.pagination.page, this.filter.search, this.filter.isDebtor,
            this.filter.type, this.filter.isApprowed, this.filter.quarter, this.filter.isQuarter, this.filter.isAwaitingConfrim).then(resp => {
                runInAction(() => {
                    this.users = resp.data.data;
                    this.pagination = resp.data.meta.pagination;
                });
            });
    };

    getUserInfoByYear = (id, year) => {
        this.isDataLoading = true;
        ApiService.getBillsResultsByYear(id, year).then(resp => {
            runInAction(() => {
                this.userData = resp.data.profile
                this.isDataLoading = false;
            });
        });
    };

    updateUserInfoByYear = (id, year) => {
        ApiService.getBillsResultsByYear(id, year).then(resp => {
            runInAction(() => this.userData = resp.data.profile);
        });
    };

    changeFilter = (key, value) => {
        if (key === 'search') {
            this.pagination.page = 1;
        };
        this.filter[key] = value;
    }

    refreshFilter = () => {
        this.pagination.page = 1;
        this.filter = {
            search: '',
            isDebtor: 'false',
            type: 'ASC',
            isApprowed: 'false',
            quarter: '',
            isQuarter: 'false',
            isAwaitingConfrim: 'false',
        };
    };
};

export const usersAdminModel = new UsersAdminModel();
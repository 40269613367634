import React from 'react';
import { useHistory } from "react-router-dom";

import './EmailSentTemplate.scss';

const EmailSentTemplate = ({ setEmailSent }) => {
  const history = useHistory();
  const toSignIn = () => history.push('/sign_in');

  return (
    <div className="email_sent_wrapper">
      <h1 className="title">Перевірте електронну пошту</h1>
      <h1 className="desc">На вказану вами електронну пошту був надісланий лист з відновленням паролю.</h1>
      <h1 className="goback">Не отримали листа? <b onClick={() => setEmailSent(false)}>Надіслати ще раз</b></h1>
      <span className="gosignin" onClick={toSignIn}>Повернутися на головну</span>
    </div>
  )
}

export default EmailSentTemplate;
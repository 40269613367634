import React, { useState, useRef } from 'react';
import { inject, observer } from "mobx-react";
import moment from "moment";
import Popup from 'reactjs-popup';
import Loader from "react-loader-spinner";

import ApiService from '../../../service/ApiService';
import BasicListItem from '../../../res/BasicListItem/BasicListItem';
import BasicButton from '../../../res/BasicButton/BasicButton';
import DeletePopup from '../DeletePopup/DeletePopup';
import EditResult from '../../popups/EditResult/EditResult';

import './ShowUserPopup.scss';

const years = ["2019", "2020", "2021", "2022", "2023"];

const ShowUserPopup = (props) => {
  const { id, children,
    usersAdminModel: {
      userData, getUserInfoByYear, isDataLoading, updateUserInfoByYear, getAllUsers
    }
  } = props;

  const [activeTab, setActiveTab] = useState("data");
  const date = new Date();
  const [searchYear, setSearchYear] = useState(date.getFullYear().toString());
  const [prepayment, setPrepayment] = useState(null);
  const ref = useRef()
  const approveUser = (close) => {
    ApiService.changeUserStatus("approve", userData.user_id).then(() => {
      getUserInfoByYear(id, searchYear);
      getAllUsers();
      close();
    })
  }
  const rejectUser = (close) => {
    ApiService.changeUserStatus("reject", userData.user_id).then(() => {
      getUserInfoByYear(id, searchYear);
      getAllUsers();
      close();
    })
  }

  const changePaymentStatus = (billingId, status, close) => {
    ApiService.changePaymentStatus(billingId, status).then(() => {
      getUserInfoByYear(id, searchYear);
      getAllUsers();
      close();
    })
  }

  const handleAddPrepayment = () => {
    if(prepayment === 'e' || prepayment <=0){
      alert("Предоплата має бути більшою ніж 0");
      return;
    }
    ApiService.addPrepayment(id, prepayment).then((r) => {
      alert("Предоплата внесена");
      ref.current.value = null;
      setPrepayment(null); 
    })
  }

  return (
    <div onClick={() => getUserInfoByYear(id, searchYear)} className="view-report-admin-pop-up-wrapper">
      <Popup
        className="view_report_admin_popup"
        trigger={open => (children)}
        modal
        closeOnDocumentClick={true}
        contentStyle={isDataLoading && { background: 'transparent', border: 'none' }}
        overlayStyle={isDataLoading && { background: 'transparent' }}
      >
        {close => (isDataLoading ? <Loader color="#007BB0" type='BallTriangle' width={150} height={150} /> :
          <>
            <div className="tabs">
              <div className={`item ${activeTab === "data" ? "active" : null}`} onClick={() => setActiveTab("data")}>
                Інформація
              </div>
              <div className={`item ${activeTab === "billing" ? "active" : null}`} onClick={() => setActiveTab("billing")}>
                Рахунки
              </div>
              <div className={`item ${activeTab === "results" ? "active" : null}`} onClick={() => setActiveTab("results")}>
                Показники
              </div>
            </div>

            <h1 className="title" style={{ fontSize: '18px', margin: "7px 0" }}>{userData.counter_number}</h1>
            {activeTab !== "data" ? null :
              <div className="data">
                <BasicListItem label={"ПІБ"} value={`${userData.last_name} ${userData.name} ${userData.middle_name}`} style={itemStyle.text} />
                <BasicListItem label={"Вулиця"} value={userData.street} style={itemStyle.text} />
                <BasicListItem label={"Номер будинку"} value={userData.building_number} style={itemStyle.text} />
                <BasicListItem label={"Номер квартири"} value={userData.flat_number} style={itemStyle.text} />
                <BasicListItem label={"Номер лічильника"} value={userData.counter_number} style={itemStyle.text} />
                <BasicListItem label={"Квартал"} value={userData.quarter_name} style={itemStyle.text} />
                <BasicListItem label={"Пошта"} value={userData.email} style={itemStyle.text} />
                <BasicListItem label={"Номер телефону"} value={userData.phone_number} style={itemStyle.text} />
                <BasicListItem label={"Передоплата"} value={`${userData.prepayment ? (userData.prepayment / 100).toFixed(2) : '00.00'} грн`} style={itemStyle.text} />
                <BasicListItem label={"Неоплачені кВт"} value={userData.unpaid_kilowatts ? userData.unpaid_kilowatts : '0'} style={itemStyle.text} />
                <BasicListItem label={"Коментар"} value={userData.notification ? userData.notification : "Немає інформації"} style={itemStyle.text} />

                <BasicListItem
                  label={"Статус"}
                  value={userData.approved ? "Підтверджений" : "Очікує на підтвердження"}
                  style={userData.approved ? itemStyle.confimed : itemStyle.unconfimed}
                />

                <div className="show_user_btn_wrapper">
                  {!userData.approved ?
                    <div className="confirm" style={{ fontSize: "16px" }} onClick={() => approveUser(close)}>Підтвердити</div> : null}
                  <DeletePopup remove={() => rejectUser(close)}>
                    <div className="delete" onClick={() => rejectUser()} style={{ fontSize: "16px" }}>Видалити</div>
                  </DeletePopup>
                </div>
              </div>
            }

            {activeTab !== "billing" ? null :
              <div className="data">
                <div className = "biling_data_header">
                  <select
                    onChange={(e) => {
                      setSearchYear(e.target.value);
                      updateUserInfoByYear(id, e.target.value);
                    }}
                    value={searchYear} >
                    {years.map((year, key) => (
                      <option key={key} value={year}>{year}</option>
                    ))}
                  </select>
                  <div className="prepayment_form">
                    
                    <input 
                      type="number"
                      ref = {ref}
                      placeholder = "Внести передплату"
                      className = "prepayment" 
                      value = {prepayment} 
                      onChange = {e => setPrepayment(e.target.value)}/>
                      <BasicButton onClick={handleAddPrepayment} label="Внести" width="100px" />
                  </div>

                </div>
                
                {userData.bills.map((item, key) => (
                  <>
                    <h1 style={{ fontSize: "14px", marginBottom: "0" }}>Рахунок номер {item.id}{item?.bill_type !=='payment' && '-П'}</h1>
                    <BasicListItem label={"Ціна"} value={`${(item.price / 100).toFixed(2)}`} style={itemStyle.text} />
                    <BasicListItem label={"Штраф"} value={`${(item.extra_price / 100).toFixed(2)}`} style={itemStyle.text} />
                    {item.status === "unpaid" && <BasicListItem label={"Сплачена частина"} value={`${(item.part_price / 100).toFixed(2)}`} style={itemStyle.text} />}
                    <BasicListItem
                      label={"Cтатус"}
                      value={`${item.status === "paid" ? "Оплачено" : "Неоплачено"}`}
                      style={item.status === "paid" ? itemStyle.confimed : itemStyle.unconfimed}
                    />
                    <div className="show_user_btn_wrapper">
                      <div
                        className="change_billing"
                        onClick={item.status === "paid" ?
                          () => changePaymentStatus(item.id, "unpaid", close) :
                          () => changePaymentStatus(item.id, "paid", close)}
                      >
                        {item.status === "paid" ? "Анулювати платіж" : "Підтвердити платіж"}
                      </div>
                    </div>
                  </>
                ))}
              </div>
            }
            {activeTab !== "results" ? null :
              <div className="data">
                <select
                  onChange={(e) => {
                    setSearchYear(e.target.value);
                    updateUserInfoByYear(id, e.target.value);
                  }}
                  value={searchYear} >
                  {years.map((year, key) => (
                    <option key={key} value={year}>{year}</option>
                  ))}
                </select>
                {userData.results.map((item, key) => (
                  <>
                    <h1 style={{ fontSize: "14px" }}>Рахунок номер {item.id} </h1>
                    <BasicListItem label={"Показник"} value={`${item.first_number},${item.last_number ? item.last_number : 0}`} style={itemStyle.text} />
                    <BasicListItem label={"Дата створення"} value={`${moment(item.created_at).format("DD.MM.YYYY")}`} style={itemStyle.text} />
                    {key === 0
                      ? <div className="edit">
                        <EditResult
                          closeParrent={close}
                          user={userData}
                          refresh={getAllUsers}
                          lastResultFirstNumber={item.first_number}
                          lastResultLastNumber={item.last_number} >
                          <BasicButton label="Редагувати" width="120px" />
                        </EditResult>
                      </div>
                      : null}
                       
                  </>
                ))}
              </div>
            }
          </>
        )}
      </Popup >
    </div>
  )
}

const itemStyle = {
  text: {
    fontSize: "13px",
    padding: "0px 10px",
    margin: "7px 0",
  },
  confimed: {
    fontSize: "13px",
    padding: "0px 10px",
    color: "green",
    margin: "7px 0",
  },
  unconfimed: {
    fontSize: "13px",
    padding: "0px 10px",
    color: "red",
    margin: "7px 0",
  },
}

export default inject('usersAdminModel')(observer(ShowUserPopup));
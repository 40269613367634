import React from 'react';

import './BasicListItem.scss';

const BasicListItem = ({ label, value, style }) => {
    return <div className="basic-list-item-wrapper" >
        <h1 style={style} className="label">{label}</h1>
        <h1 style={style} className="value">{value ? value : "Незаповнені дані"}</h1>
    </div>
}

export default BasicListItem;
import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { inject, observer } from "mobx-react";
import Files from "react-butterfiles"
import Loader from "react-loader-spinner";

import ApiService from '../../service/ApiService';
import ShowAllAnswers from '../../components/popups/ShowAllAnswers/ShowAllAnswers';
import BasicButton from '../../res/BasicButton/BasicButton';
import ImgBuilder from '../../img/ImgBuilder';
import { phoneRegExp } from '../../const/regular_expressions';

import './Feedback.scss';

const Feedback = ({ userModel: { profile } }) => {
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const openPopup = () => setIsOpenPopup(true);
    const closePopup = () => setIsOpenPopup(false);

    const [attachmentName, setAttachmentName] = useState('');
    const [errorAttachment, setErrorAttachment] = useState('');

    if (Object.keys(profile).length === 0) {
        return (
            <div className="loader_wrapper">
                <Loader color="#007BB0" type='BallTriangle' width={150} height={150} />
            </div>
        )
    }
    return (
        <>
            <div className="feedback_wrapper">
                <div className="feedback">
                    <div className="title_wrapper">
                        <h1 className="title">Зворотній зв’язок</h1>
                        <BasicButton label="Відкрити усі питання" width="200px" onClick={openPopup} />
                    </div>
                    <h1 className="sub_title">
                        Тут Ви можете задати питання, яке Вас цікавить.
                    </h1>
                    {Object.keys(profile).length !== 0 && <Formik
                        initialValues={{
                            name: `${profile.last_name} ${profile.name} ${profile.middle_name}`,
                            email: profile.email,
                            phone_number: profile.phone_number,
                            question: "",
                        }} email
                        validationSchema={Yup.object().shape({
                            name: Yup.string().required("Обов'язкове поле"),
                            email: Yup.string().required("Обов'язкове поле").email("Невірний формат пошти"),
                            question: Yup.string().required("Обов'язкове поле").max(500, 'Перевищено дозволену кількість символів'),
                            phone_number: Yup.string().required("Обов'язкове поле").matches(phoneRegExp, 'Неправильний номер телефону'),
                        })}
                        onSubmit={feedback => {
                            const formData = new FormData();
                            Object.keys(feedback).map((key) => {
                                if (feedback[key] !== null) {
                                    formData.set(`feedback[${key}]`, feedback[key]);
                                };
                            });
                            ApiService.askQuiestion(formData).then(() => {
                                alert(`Питання отримано, очікуйте на відповідь від адміністратора.`);
                                document.location.reload();
                            })
                        }}
                        render={({ errors, values, handleChange, setFieldValue, handleSubmit }) => (
                            <Form>

                                <div className="form_item">
                                    <h1 className="form_label">Ім’я</h1>
                                    <input
                                        type="text"
                                        placeholder="Введіть ім’я"
                                        className={`form_input ${errors.name ? "error" : null}`}
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange} />
                                    <h1 className="error_text">{errors.name ? errors.name : ""}</h1>
                                </div>

                                <div className="form_item">
                                    <h1 className="form_label">Електронна пошта</h1>
                                    <input
                                        type="text"
                                        placeholder="Введіть пошту"
                                        className={`form_input ${errors.email ? "error" : null}`}
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange} />
                                    <h1 className="error_text">{errors.email ? errors.email : ""}</h1>
                                </div>

                                <div className="form_item">
                                    <h1 className="form_label">Номер телефону</h1>
                                    <input
                                        type="text"
                                        placeholder="Введіть номер телефону"
                                        className={`form_input ${errors.phone_number ? "error" : null}`}
                                        name="phone_number"
                                        value={values.phone_number}
                                        onChange={handleChange} />
                                    <h1 className="error_text">{errors.phone_number ? errors.phone_number : ""}</h1>
                                </div>

                                <div className="form_item">
                                    <h1 className="form_label">Ваше питання</h1>
                                    <textarea
                                        type="text"
                                        placeholder="Введіть ваше питання"
                                        className={`form_input form_textarea  ${errors.question ? "error" : null}`}
                                        name="question"
                                        value={values.question}
                                        onChange={handleChange} />
                                    {values.question.length <= 500 && <p className='textarea_lenght'>{`Дозволена кількість символів - ${500 - values.question.length}`}</p>}
                                    <h1 className="error_text">{errors.question ? errors.question : ""}</h1>
                                </div>

                                <div className="form_item">
                                    <Files
                                        multiple={false}
                                        maxSize="3mb"
                                        accept={["image/png", "image/jpg", "image/jpeg"]}
                                        onSuccess={file => {
                                            setErrorAttachment('');
                                            setFieldValue('attachment', file[0].src.file);
                                            setAttachmentName(file[0].src.file.name);
                                        }}
                                        onError={errors => {
                                            if (errors[0].type === 'maxSizeExceeded') {
                                                setErrorAttachment('Файл не завантажено. Оберіть файл, менший аніж 3 мБ');
                                                setFieldValue('attachment', null);
                                                setAttachmentName('');
                                            }
                                        }}
                                    >
                                        {({ browseFiles, getDropZoneProps, getLabelProps }) => (
                                            <>
                                                <label className="form_label" {...getLabelProps()}>Додати файл (не обов'язково, максимальний об'єм - 3мБ)</label>
                                                <div {...getDropZoneProps({ className: "myDropZone" })} />
                                                <button className="btn_file" type='button' onClick={browseFiles}>Додати файл</button>
                                                {attachmentName && <span className="attachment_name">
                                                    {attachmentName}
                                                    <img className="attachment_delete"
                                                        src={ImgBuilder.close}
                                                        alt="delete"
                                                        onClick={() => {
                                                            setFieldValue('attachment', null);
                                                            setAttachmentName('');
                                                        }}
                                                    />
                                                </span>}
                                                {errorAttachment && <h1 className="error_text">{errorAttachment}</h1>}
                                            </>
                                        )}
                                    </Files>
                                </div>

                                <button className="btn_wrapper" type='submit' onClick={handleSubmit}>
                                    <BasicButton label="Надіслати" width="156px" />
                                </button >
                            </Form>
                        )}
                    />}
                </div>
            </div>
            {isOpenPopup && <ShowAllAnswers isOpenPopup={isOpenPopup} closePopup={closePopup} />}
        </>
    )
}

export default inject('userModel')(observer(Feedback));

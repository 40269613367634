import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import ImgBuilder from '../../../img/ImgBuilder';
import BasicButton from '../../../res/BasicButton/BasicButton';
import ApiService from '../../../service/ApiService';

import './ResetPassword.scss';

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [resetData, setResetData] = useState({
    email: getQuery().get("email"),
    token: getQuery().get("reset_token")
  });

  return (
    <div className="reset_password_page">
      <div className="reset_password_wrapper">
        <div className=" content">
          <h1 className="title">Новий пароль</h1>
          <Formik
            initialValues={{
              password: '',
              confirm_password: '',
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string().required("Обов'язкове поле").min(6, "Мінімум 6 символів"),
              confirm_password: Yup.string().required("Обов'язкове поле")
                .oneOf([Yup.ref('password'), null], 'Паролі мають співпадати'),
            })}
            onSubmit={data => {
              var formData = new FormData();
              formData.append("email", getQuery().get("email"));
              formData.append("token", getQuery().get("reset_token"));
              formData.append("password", data.password);
              ApiService.reset_password(formData).then(() => {
                window.location.replace("/sign_in")
              })
            }}
            render={({ errors, values, handleChange }) => (
              <Form>
                <div className="form_item">
                  <h1 className="form_label">Пароль</h1>
                  <img src={ImgBuilder.eye} className="viewPassword" alt="eye" onClick={() => setShowPassword((prev) => !prev)} />
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Введіть пароль"
                    className={`form_input ${errors.email ? "error" : null}`}
                    name="password"
                    value={values.password}
                    onChange={handleChange} />
                  <h1 className="error_text">{errors.password ? errors.password : ""}</h1>
                </div>

                <div className="form_item">
                  <h1 className="form_label">Підтвердити пароль</h1>
                  <img src={ImgBuilder.eye} className="viewPassword" alt="eye" onClick={() => setShowPassword((prev) => !prev)} />
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Підтвердіть пароль"
                    className={`form_input ${errors.confirm_password ? "error" : null}`}
                    name="confirm_password"
                    value={values.confirm_password}
                    onChange={handleChange} />
                  <h1 className="error_text">{errors.confirm_password ? errors.confirm_password : ""}</h1>
                </div>
                <button className="btn_wrapper" onClick={null}>
                  <BasicButton label="Відновити" width="156px" />
                </button >
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  )
}

const getQuery = () => {
  return new URLSearchParams(window.location.search);
}

export default ResetPassword;
import React from 'react';
import { inject, observer } from "mobx-react";

import AdminDashboard from './AdminDashboard/AdminDashboard';
import UserDashboard from './UserDashboard/UserDashboard';

import './Dashboard.scss'

const Dashboard = ({ authModel: { user } }) => {

    if (user.role !== "user") {
        return <div className="dashboard_wrapper_admin">
            <AdminDashboard />
        </div>
    }
    return <div className="dashboard_wrapper">
        <UserDashboard />
    </div>
}

export default inject('authModel')(observer(Dashboard));
import React from 'react';

import './style.scss';

const BasicPageIntro = ({ children }) => {
  return (
    <div className="basic_page_intro">
      <div className="intro_header">
        <h1 className="intro_title">КК "Козацький"</h1>
      </div>
      {children}
      <div className="intro_footer">
        <p className="intro_contacts">
          Гаряча лінія обленерго:
          <span className="intro_number">1) 0 800 30 15 68 (безкоштовно);</span>
          <span className="intro_number">2) 067 333 15 68;</span>
          <span className="intro_number">3) 093 170 15 68;</span>
        </p>
        <div className="footer_copyright">
          <p className="footer_copyright_text">
            © КК «Козацький». All right reserved.
            <span className="footer_copyright_company">
              Developed by
            <a href="http://sunscript.com.ua/" rel="noopener noreferrer" target="_blank" className="footer_copyright_link">
                SunScript.
            </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default BasicPageIntro;

import React, { useState, useEffect, useRef } from 'react';
import { inject, observer } from "mobx-react";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import Recaptcha from 'react-google-invisible-recaptcha';

import ImgBuilder from '../../../img/ImgBuilder';
import BasicButton from '../../../res/BasicButton/BasicButton';
import { SITE_KEY_RECAPTCHA } from '../../../const/recaptchaV3';

import './SignIn.scss';

const SignIn = ({ authModel: { signIn, serverError, refreshServerError } }) => {
  const history = useHistory();

  const [showPassword, setShowPassword] = useState(false);

  const recaptchaRef = useRef();
  const runRecaptcha = () => recaptchaRef.current.execute();

  useEffect(() => {
    return () => refreshServerError();
  }, []);

  return (
    <div className="sign_in_page">
      <div className="sign_in_wrapper">
        <div className=" content">
          <h1 className="title">Увійти</h1>
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validateOnBlur
            validationSchema={Yup.object().shape({
              email: Yup.string().required("Обов'язкове поле").email("Невірний формат пошти"),
              password: Yup.string().required("Обов'язкове поле"),
            })}
            onSubmit={user => signIn({ user })}
          >
            {({ errors, touched, values, handleChange, handleSubmit }) => (
              <Form>
                <div className="form_item">
                  <h1 className="form_label">Електронна пошта</h1>
                  <input
                    type="text"
                    placeholder="Введіть пошту"
                    className={`form_input ${errors.email ? "error" : null}`}
                    name="email"
                    value={values.email}
                    onChange={handleChange} />
                  {(errors.email && touched.email) && <h1 className="error_text">{errors.email}</h1>}
                </div>
                <div className="form_item">
                  <h1 className="form_label">Пароль</h1>
                  <img src={ImgBuilder.eye} alt="eye" className="viewPassword" onClick={() => setShowPassword((prevState) => !prevState)} />
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Введіть пароль"
                    className={`form_input ${errors.email ? "error" : null}`}
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                  />
                  {(errors.password && touched.password) && <h1 className="error_text">{errors.password}</h1>}
                </div>
                {serverError ? <h1 className="error_text server"> {serverError.signIn} </h1> : null}
                <button className="btn_wrapper" onClick={runRecaptcha}>
                  <BasicButton label="Увійти" width="156px" />
                </button >
                <Recaptcha
                  ref={recaptchaRef}
                  sitekey={SITE_KEY_RECAPTCHA}
                  onResolved={handleSubmit}
                />
                <h1 className="forgot_password" onClick={() => history.push("/forgot_password")}>Забули пароль? </h1>
                <h1 className="no_account">Немає аккаунту?
                  <span className="sign_up" onClick={() => history.push("/sign_up")}> Зареєструватись</span>
                </h1>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default inject('authModel')(observer(SignIn));
import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import BasicButton from '../../../res/BasicButton/BasicButton';
import EmailSentTemplate from './emailSentTemplate/EmailSentTemplate';
import ApiService from '../../../service/ApiService';

import './ForgotPassword.scss';

const ForgotPassword = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [serverError, setServerError] = useState("");

  if (emailSent) {
    return <EmailSentTemplate setEmailSent={(e) => setEmailSent(e)} />
  }
  return (
    <div className="forgot_password_page">
      <div className="forgot_password_wrapper">
        <div className=" content">
          <h1 className="title">Відновити пароль</h1>
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().required("Обов'язкове поле").email("Невірний формат пошти"),
            })}
            onSubmit={user => {
              var formData = new FormData();
              formData.append("email", user.email)
              ApiService.forgot_password(formData).then((resp) => {
                setEmailSent(true)
              }).catch(() => {
                setServerError("Пошта не зареєстрована")
              })
            }}
            render={({ errors, values, handleChange }) => (
              <Form>
                <div className="form_item">
                  <h1 className="form_label">Електронна пошта</h1>
                  <input
                    type="text"
                    placeholder="Введіть пошту"
                    className={`form_input ${errors.email ? "error" : null}`}
                    name="email"
                    value={values.email}
                    onChange={handleChange} />
                  <h1 className="error_text">{errors.email ? errors.email : ""}</h1>
                  <h1 className="error_text">{serverError && !errors.email ? serverError : ""}</h1>
                </div>
                <button className="btn_wrapper" onClick={null}>
                  <BasicButton label="Відновити" width="156px" />
                </button >
                <h1 className="forgot_password" onClick={() => window.location.replace("/sign_in")}>Повернутися назад</h1>
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword;
import React from 'react';
// import { inject, observer } from "mobx-react";
// import Loader from "react-loader-spinner";

import SettingsPrice from './SettingsPrice/SettingsPrice';
import SettingsQuarters from './SettingsQuarters/SettingsQuarters';
import ChangePassword from '../../components/popups/ChangePassword/ChangePassword';
import ChangeEmail from '../../components/popups/ChangeEmail/ChangeEmail';

import './Settings.scss';

const Settings = () => {

  // if (quarters.length === 0 || Object.keys(settings).length === 0) {
  //   return (
  //     <div className="loader_wrapper">
  //       <Loader color="#007BB0" type='BallTriangle' width={150} height={150} />
  //     </div>
  //   )
  // }
  return (
    <div className="settings_wrapper">
      <div className="flex_end">
        <div className="change_wrapper">
          <ChangeEmail><span className="change_item">Змінити email</span></ChangeEmail>
          <ChangePassword><span className="change_item">Змінити пароль</span></ChangePassword>
        </div>
      </div>
      <div className="line">
        <SettingsPrice />
        <SettingsQuarters />
      </div>
    </div>
  )
}

export default Settings;
import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { inject, observer } from "mobx-react";

import ApiService from '../../../service/ApiService';

import './AddResult.scss';

const AddResult = ({ children, userModel: { profile, lastResult, getLastResult, updateResult } }) => {
    const [error, setError] = useState(false);

    useEffect(() => {
        setError(false);
    }, [])

    const blockScroll = () => {
        if (document.activeElement.type === "number") {
            document.activeElement.blur();
        };
    };

    useEffect(() => {
        document.addEventListener("wheel", blockScroll);
        return () => document.removeEventListener("wheel", blockScroll)
    })

    const handleChange = (e) => {
        let newValue = e.target.value;
        let lastInputChar = getDifference(lastResult[e.target.name], newValue);
        if (lastInputChar) {
            updateResult({ ...lastResult, [e.target.name]: lastInputChar });
            focusNextInput(e.target.id);
        }
    }

    // 8 is amount of inputs from 0 [0,1,2,3,4,5,6,7];
    const focusNextInput = (inputId) => {
        let currentInputInt = parseInt(inputId);
        let nextInputId = (currentInputInt + 1).toString();
        if (currentInputInt === 7) {
            document.getElementById(0).focus();
        } else {
            document.getElementById(nextInputId).focus();
        }
    }

    const focusPrevInput = (inputId) => {
        let currentInputInt = parseInt(inputId);
        let prevInputId = (currentInputInt - 1).toString();
        if (currentInputInt === 0) {
            document.getElementById(7).focus();
        } else {
            document.getElementById(prevInputId).focus();
        }
    }

    const onKeyDown = (e) => {
        if (e.keyCode === 8) {
            updateResult({ ...lastResult, [e.target.name]: "0" });
            focusPrevInput(e.target.id)
        }
    }

    const saveData = (close) => {
        ApiService.saveCounterResult({
            result: {
                first_number: `${lastResult.one}${lastResult.two}${lastResult.three}${lastResult.four}${lastResult.five}${lastResult.six}`,
                last_number: `${lastResult.seven}${lastResult.eight}`
            }
        }).then(() => {
            window.location.reload();
            close()
        }).catch(() => {
            setError("Показник має бути більшим ніж попереднього разу!");
        })
    }

    return (
        <div className="add-result-pop-up-wrapper">
            <Popup
                trigger={() => (children)}
                modal
                closeOnDocumentClick={true}
                onClose={() => {
                    setError(false);
                    getLastResult();
                }}
            >
                {close => (
                    <>
                        <div className="add-result-pop-up-header"></div>
                        <div className="counter">
                            <h1 className="text _12">Лічильник</h1>
                            <h1 className="text _14">№{profile.counter_number}</h1>
                            <h1 className="text _18" >Поточні покази</h1>
                            <div className="numbers">
                                <input
                                    className="number one"
                                    type="number"
                                    placeholder="0"
                                    id="0"
                                    value={lastResult.one}
                                    name="one"
                                    onKeyDown={onKeyDown}
                                    onChange={e => handleChange(e)}
                                />

                                <input
                                    className="number two"
                                    type="number"
                                    placeholder="0"
                                    id="1"
                                    value={lastResult.two}
                                    name="two"
                                    onChange={e => handleChange(e)}
                                    onKeyDown={onKeyDown}
                                />

                                <input
                                    className="number three"
                                    type="number"
                                    placeholder="0"
                                    id="2"
                                    value={lastResult.three}
                                    name="three"
                                    onChange={e => handleChange(e)}
                                    onKeyDown={onKeyDown}
                                />

                                <input
                                    className="number four"
                                    type="number"
                                    placeholder="0"
                                    value={lastResult.four}
                                    name="four"
                                    id="3"
                                    onChange={e => handleChange(e)}
                                    onKeyDown={onKeyDown}
                                />

                                <input
                                    className="number four"
                                    type="number"
                                    placeholder="0"
                                    value={lastResult.five}
                                    name="five"
                                    id="4"
                                    onChange={e => handleChange(e)}
                                    onKeyDown={onKeyDown}
                                />

                                <input
                                    className="number four"
                                    type="number"
                                    placeholder="0"
                                    value={lastResult.six}
                                    name="six"
                                    id="5"
                                    onChange={e => handleChange(e)}
                                    onKeyDown={onKeyDown}
                                />
                                <input
                                    className="number six"
                                    type="number"
                                    placeholder="0"
                                    value={lastResult.seven}
                                    name="seven"
                                    id="6"
                                    onChange={e => handleChange(e)}
                                    onKeyDown={onKeyDown}
                                />
                                <input
                                    className="number six"
                                    type="number"
                                    id="7"
                                    placeholder="0"
                                    value={lastResult.eight}
                                    name="eight"
                                    onChange={e => handleChange(e)}
                                    onKeyDown={onKeyDown}
                                />
                            </div>
                            {error ? <div className="error">{error}</div> : ""}

                            <div className="save" onClick={() => saveData(close)}>
                                Подати покази
                            </div>
                            <div className="cancel" onClick={close}>Скасувати</div>
                        </div>
                    </>
                )}
            </Popup >
        </div>
    )
}

function getDifference(a, b) {
    var i = 0;
    var j = 0;
    var result = "";

    while (j < b.length) {
        if (a[i] !== b[j] || i === a.length)
            result += b[j];
        else
            i++;
        j++;
    }
    return result;
}

export default inject('userModel')(observer(AddResult));
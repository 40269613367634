import React, { useState, useEffect } from 'react';
import { inject, observer } from "mobx-react";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import ApiService from '../../../service/ApiService';
import BasicButton from '../../../res/BasicButton/BasicButton';
import { decimalRegex } from '../../../const/regular_expressions';

import './style.scss';

const SettingsPrice = ({ adminModel: { getSettings, settings } }) => {
  const [isSucces, setIsSuccess] = useState(false);

  const updateSetings = (data) => {
    setIsSuccess(false)
    ApiService.updateSettings(data).then(() => {
      setIsSuccess(true);
      getSettings();
    }).catch(() => {
      setIsSuccess(false)
    })
  };

  const blockScroll = () => {
    if (document.activeElement.type === "number") {
      document.activeElement.blur();
    };
  };

  useEffect(() => {
    document.addEventListener("wheel", blockScroll);
    return () => document.removeEventListener("wheel", blockScroll)
  })

  return (
    <>
      {Object.keys(settings).length !== 0 && <div className="settings_block">
        <Formik
          initialValues={settings}
          validationSchema={Yup.object().shape({
            bill_per_vat: Yup.string().required("Обов'язкове поле").matches(decimalRegex, 'Невірний формат тарифу (приклад коректного тарифу - "2.54")'),
            extra_bill_percent: Yup.string().required("Обов'язкове поле").matches(decimalRegex, 'Невірний формат тарифу (приклад коректного тарифу - "2.54")'),
            reminder_day: Yup.number().required("Обов'язкове поле").integer('Введіть ціле число').min(1, 'Введіть число більше за 1').max(31, 'Введіть число менше за 32').typeError('Введіть ціле число'),
            penalty_period: Yup.number().required("Обов'язкове поле").positive('Введіть додатнє число').integer('Введіть ціле число').typeError('Введіть ціле число'),
            accrual_result_day: Yup.number().required("Обов'язкове поле").integer('Введіть ціле число').min(1, 'Введіть число більше за 1').max(31, 'Введіть число менше за 32').typeError('Введіть ціле число'),
            admin_notification_day: Yup.number().required("Обов'язкове поле").integer('Введіть ціле число').min(1, 'Введіть число більше за 1').max(31, 'Введіть число менше за 32').typeError('Введіть ціле число'),
          })}
          onSubmit={(data) => {
            updateSetings({
              setting: {
                bill_per_vat: data.bill_per_vat * 100,
                extra_bill_percent: data.extra_bill_percent,
                reminder_day: data.reminder_day,
                penalty_period: data.penalty_period,
                accrual_result_day: data.accrual_result_day,
                admin_notification_day: data.admin_notification_day,
              }
            })
          }}
        >
          {({ errors, values, handleChange, handleSubmit }) => (
            <Form>
              <div className="form_item">
                <h1 className="form_label">Ціна за кіловат:</h1>
                <input
                  autoCorrect={false}
                  spellCheck={false}
                  type="text"
                  placeholder="Введіть ціну"
                  className={`form_input_settings ${errors.extra_bill_percent ? "error" : null}`}
                  name="bill_per_vat"
                  value={values.bill_per_vat}
                  onChange={handleChange} />
                <h1 className="error_text">{errors.bill_per_vat ? errors.bill_per_vat : ""}</h1>
              </div>

              <div className="form_item">
                <h1 className="form_label">Штраф за протермінування у %:</h1>
                <input
                  autoCorrect={false}
                  spellCheck={false}
                  type="number"
                  placeholder="Введіть штраф"
                  className={`form_input_settings ${errors.extra_bill_percent ? "error" : null}`}
                  name="extra_bill_percent"
                  value={values.extra_bill_percent}
                  onChange={handleChange} />
                <h1 className="error_text">{errors.extra_bill_percent ? errors.extra_bill_percent : ""}</h1>
              </div>

              <div className="form_item">
                <h1 className="form_label">День місяця, коли приходить сповіщення про подання показників:</h1>
                <input
                  autoCorrect={false}
                  spellCheck={false}
                  type="number"
                  placeholder="Введіть день"
                  className={`form_input_settings ${errors.reminder_day ? "error" : null}`}
                  name="reminder_day"
                  value={values.reminder_day}
                  onChange={handleChange} />
                <h1 className="error_text">{errors.reminder_day ? errors.reminder_day : ""}</h1>
              </div>

              <div className="form_item">
                <h1 className="form_label">Період, після якого нараховується пеня (у днях):</h1>
                <input
                  autoCorrect={false}
                  spellCheck={false}
                  type="number"
                  placeholder="Введіть кількість днів"
                  className={`form_input_settings ${errors.penalty_period ? "error" : null}`}
                  name="penalty_period"
                  value={values.penalty_period}
                  onChange={handleChange} />
                <h1 className="error_text">{errors.penalty_period ? errors.penalty_period : ""}</h1>
              </div>

              <div className="form_item">
                <h1 className="form_label">День місяця, коли автоматично нараховується показник, якщо його не подали:</h1>
                <input
                  autoCorrect={false}
                  spellCheck={false}
                  type="number"
                  placeholder="Введіть день"
                  className={`form_input_settings ${errors.accrual_result_day ? "error" : null}`}
                  name="accrual_result_day"
                  value={values.accrual_result_day}
                  onChange={handleChange} />
                <h1 className="error_text">{errors.accrual_result_day ? errors.accrual_result_day : ""}</h1>
              </div>

              <div className="form_item">
                <h1 className="form_label">День місяця, коли адміну приходять сповіщення з користувачами, які не подали показники:</h1>
                <input
                  autoCorrect={false}
                  spellCheck={false}
                  type="number"
                  placeholder="Введіть день"
                  className={`form_input_settings ${errors.admin_notification_day ? "error" : null}`}
                  name="admin_notification_day"
                  value={values.admin_notification_day}
                  onChange={handleChange} />
                <h1 className="error_text">{errors.admin_notification_day ? errors.admin_notification_day : ""}</h1>
              </div>
              {isSucces && <h2 className="saved">Зміни збережено</h2>}

              <button className="btn_wrapper">
                <BasicButton label="Зберегти" width="156px" onClick={handleSubmit} />
              </button >
            </Form>
          )}
        </Formik>
      </div>}
    </>
  )
}

export default inject('adminModel')(observer(SettingsPrice));

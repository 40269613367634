import React from 'react';
import { inject, observer } from "mobx-react";

import BasicButton from '../../../../res/BasicButton/BasicButton';
import EditProfilePopup from '../../../../components/popups/EditProfilePopup/EditProfilePopup';
import ApiService from '../../../../service/ApiService';

import './PersonalData.scss'

const PersonalData = ({ userModel: { profile } }) => {
    const resentConfirmEmail = () => {
        ApiService.resentConfirmEmail().then(() => alert("Лист відправлено на Вашу електронну пошту."))
            .catch(() => alert("Щось пішло не так, лист не відправлено."))
    }

    return <div className="personal-data">
        <div className="header">
            <span className="title">Особисті дані</span>
            <EditProfilePopup user={profile} >
                <BasicButton label="Редагувати" width="130px" disabled={!profile.confirmed || !profile.approved} />
            </EditProfilePopup>
        </div>

        <div className="content">
            <div className="line">
                <span>ПІБ</span>
                <span>{profile.last_name} {profile.name} {profile.middle_name}</span>
            </div>
            <div className="line">
                <span>Адреса</span>
                <span>
                    {profile.street ? `вул ${profile.street}, ` : ""}
                    {profile.building_number ? `буд ${profile.building_number}, ` : ""}
                    {profile.flat_number ? `кв ${profile.flat_number}` : ""}</span>
            </div>
            <div className="line">
                <span>Телефон</span>
                <span>{profile.phone_number}</span>
            </div>
            <div className="line">
                <span>Електронна пошта</span>
                <span>{profile.email}</span>
            </div>
        </div>
        <div className="awaiting_confirm">
            {profile.awaiting_confirmation && <p className="awaiting_confirm_text">Очікуйте на підтвердження змін Ваших особистих даних.</p>}
            {!profile.approved && <p className="awaiting_confirm_text">Очікуйте на підтвердження Вашого аккаунту адміністратором.</p>}
            {!profile.confirmed && <>
                <p className="awaiting_confirm_text">Підтвердіть Ваш аккаунт на електронній пошті.</p>
                <p className="resent_confirm_email" onClick={resentConfirmEmail}>Відправити лист із підтвердженням ще раз.</p>
            </>}
        </div>
    </div>
}

export default inject('userModel')(observer(PersonalData));;
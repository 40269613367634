import React from 'react';

const BasicButton = ({ label, width, onClick = () => {}, disabled, background }) => {
    const style = {
        basic: {
            background: background ?? "#007BB0",
            borderRadius: "4px",
            height: "32px",
            width: width,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: disabled ? "auto" : "pointer",
            opacity: disabled ? "0.5" : "1",
        },
        text: {
            color: "#FFFFFF",
            fontSize: "14px",
        }
    }

    return <div style={style.basic} onClick={disabled ? null : onClick}  >
        <span style={style.text}>{label}</span>
    </div>
}

export default BasicButton;
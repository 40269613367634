import React from 'react';

import ImgBuilder from '../../../../img/ImgBuilder';

import './Pagination.scss';

const Pagination = ({ pagination, getNextPage }) => {

  const getNext = () => {
    if (+pagination.page < +pagination.total_pages) {
      getNextPage(+pagination.page + 1);
    }
  }

  const getPrevious = () => {
    if (+pagination.page > 1) {
      getNextPage(+pagination.page - 1);
    }
  }

  const getPagesOptionsArray = () => {
    let arr = []
    for (let i = 1; i <= pagination.total_pages; i++) {
      arr.push(i);
    }
    return arr;
  }

  return (
    <div className="pagination_wrapper">
      <div className="page_counter">
        <select
          className="page_counter_select"
          onChange={(e) => { getNextPage(e.target.value) }}
          value={pagination.page} >
          {getPagesOptionsArray().map((page, key) => (
            <option key={key} value={page}>{page}</option>
          ))}
        </select>
        <span className="page_counter_totalPage"> - {pagination.total_pages}</span>
      </div>

      <div className="arrows">
        <img
          src={ImgBuilder.arrow_active}
          className="arrows_image rotate"
          onClick={getPrevious}
          alt='prev'
        />
        <img
          src={ImgBuilder.arrow_active}
          className="arrows_image"
          onClick={getNext}
          alt='next'
        />
      </div>
    </div>
  )
}

export default Pagination;
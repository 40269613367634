import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import './style.scss';

const ConfirmEmail = ({ confirmEmail }) => {
  const [respMessage, setRespMessage] = useState('');

  const history = useHistory();
  const goHome = () => history.push('/dashboard');

  const { id } = useParams();

  useEffect(() => {
    confirmEmail(id)
      .then((resp) => setRespMessage(resp.data.messages))
      .catch((error) => setRespMessage(error.response.data.messages));
  }, [])

  return (
    <div className='change_old_email'>
      <div className="resp_wrapper">
        <p className="resp_message">
          {respMessage}
        </p>
        <span className="go_home" onClick={goHome}>Повернутися на головну</span>
      </div>
    </div>
  )
}

export default ConfirmEmail;

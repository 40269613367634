import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import BasicButton from '../../../res/BasicButton/BasicButton';
import { phoneRegExp } from '../../../const/regular_expressions';

import './Personal.scss'

const Personal = ({ changeBlock, updateUserData, userData }) => {

    return <div className="personal_info_wrapp">
        <div className="form">
            <h1 className="title">Ваша особиста інформація</h1>
            <Formik
                initialValues={userData}
                validationSchema={Yup.object().shape({
                    name: Yup.string().required("Обов'язкове поле"),
                    last_name: Yup.string().required("Обов'язкове поле"),
                    middle_name: Yup.string().required("Обов'язкове поле"),
                    phone_number: Yup.string().required("Обов'язкове поле").matches(phoneRegExp, 'Неправильний номер телефону'),
                })}
                validateOnBlur
                onSubmit={() => {
                    changeBlock("home_info");
                }}
            >
                {({ errors, touched, values, handleChange }) => (
                    <Form>
                        <div className="form_item">
                            <h1 className="form_label">Ім’я</h1>
                            <input
                                type="text"
                                placeholder="Введіть ім’я"
                                className={`form_input ${errors.name ? "error" : null}`}
                                name="name"
                                value={values.name}
                                onChange={(e) => {
                                    updateUserData(e)
                                    handleChange(e)
                                }} />
                            {(errors.name && touched.name) && <h1 className="error_text">{errors.name}</h1>}
                        </div>

                        <div className="form_item">
                            <h1 className="form_label">Прізвище</h1>
                            <input
                                type="text"
                                placeholder="Введіть прізвище"
                                className={`form_input ${errors.last_name ? "error" : null}`}
                                name="last_name"
                                value={values.last_name}
                                onChange={(e) => {
                                    updateUserData(e)
                                    handleChange(e)
                                }} />
                            {(errors.last_name && touched.last_name) && <h1 className="error_text">{errors.last_name}</h1>}
                        </div>

                        <div className="form_item">
                            <h1 className="form_label">По батькові</h1>
                            <input
                                type="text"
                                placeholder="Введіть по батькові"
                                className={`form_input ${errors.middle_name ? "error" : null}`}
                                name="middle_name"
                                value={values.middle_name}
                                onChange={(e) => {
                                    updateUserData(e)
                                    handleChange(e)
                                }} />
                            {(errors.middle_name && touched.middle_name) && <h1 className="error_text">{errors.middle_name}</h1>}
                        </div>

                        <div className="form_item">
                            <h1 className="form_label">Номер телефону</h1>
                            <input
                                type="text"
                                placeholder="Номер телефону"
                                className={`form_input ${errors.phone_number ? "error" : null}`}
                                name="phone_number"
                                value={values.phone_number}
                                onChange={(e) => {
                                    updateUserData(e)
                                    handleChange(e)
                                }} />
                            {(errors.phone_number && touched.phone_number) && <h1 className="error_text">{errors.phone_number}</h1>}
                        </div>
                        <button type="submit" className="next_btn">
                            <BasicButton label="Далі" width="100px" />
                        </button >
                    </Form>
                )}
            </Formik>
        </div>
    </div>
}

export default Personal;
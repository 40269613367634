import React, { useState, useEffect, useRef } from 'react';
import { inject, observer } from "mobx-react";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import Recaptcha from 'react-google-invisible-recaptcha';

import ImgBuilder from '../../../img/ImgBuilder';
import BasicButton from '../../../res/BasicButton/BasicButton';
import { SITE_KEY_RECAPTCHA } from '../../../const/recaptchaV3';

import './SignUp.scss'

const SignUp = ({ authModel: { signUp, serverError, refreshServerError } }) => {
  const history = useHistory();

  const [showPassword, setShowPassword] = useState(false);

  const recaptchaRef = useRef();
  const runRecaptcha = () => recaptchaRef.current.execute();

  const blockScroll = () => {
    if (document.activeElement.type === "number") {
      document.activeElement.blur();
    };
  };

  useEffect(() => {
    document.addEventListener("wheel", blockScroll);
    return () => document.removeEventListener("wheel", blockScroll)
  })

  useEffect(() => {
    return () => refreshServerError();
  }, [])

  return (
    <div className="sign_up_page">
      <div className="sign_up_wrapper">
        <div className=" content">
          <h1 className="title">Зареєструватись</h1>

          <Formik
            initialValues={{
              email: '',
              password: '',
              counter_id: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().required("Обов'язкове поле").email("Невірний формат пошти"),
              password: Yup.string().required("Обов'язкове поле").min(6, "Мінімум 6 символів"),
              confirmPassword: Yup.string().required("Обов'язкове поле")
                .oneOf([Yup.ref('password'), null], 'Паролі мають співпадати'),
              counter_id: Yup.number().required("Обов'язкове поле").nullable(),

            })}
            onSubmit={fields => signUp(fields)
            }
            render={({ errors, values, handleChange, setFieldValue, handleSubmit }) => (
              <Form>
                <div className="form_item">
                  <h1 className="form_label">Електронна пошта</h1>
                  <input
                    type="text"
                    placeholder="Введіть пошту"
                    className={`form_input ${errors.email ? "error" : null}`}
                    name="email"
                    value={values.email}
                    onChange={handleChange} />
                  <h1 className="error_text">{errors.email ? errors.email : ""}</h1>
                  <h1 className="error_text">{serverError && !errors.email ? serverError.email : ""}</h1>
                </div>
                <div className="form_item">
                  <h1 className="form_label">Пароль</h1>
                  <img src={ImgBuilder.eye} alt="eye" className="viewPassword" onClick={() => setShowPassword((prevState) => !prevState)} />
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Введіть пароль"
                    className={`form_input ${errors.email ? "error" : null}`}
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                  />
                  <h1 className="error_text">{errors.password ? errors.password : ""}</h1>
                </div>

                <div className="form_item">
                  <h1 className="form_label">Підтвердити пароль</h1>
                  <img src={ImgBuilder.eye} alt="eye" className="viewPassword" onClick={() => setShowPassword((prevState) => !prevState)} />
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Підтвердіть пароль"
                    className={`form_input ${errors.email ? "error" : null}`}
                    name="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleChange}
                  />
                  <h1 className="error_text">{errors.confirmPassword ? errors.confirmPassword : ""}</h1>
                </div>

                <div className="form_item">
                  <h1 className="form_label">Номер лічильника</h1>
                  <input
                    type="number"
                    placeholder="Введіть номер лічильника"
                    className={`form_input ${errors.counter_id ? "error" : null}`}
                    name="counter_id"
                    value={values.counter_id}
                    onChange={(e) => setFieldValue("counter_id", e.target.value)} />
                  <h1 className="error_text">{errors.counter_id ? errors.counter_id : ""}</h1>
                </div>
                <span></span>
                <h1 className="error_text server"> {serverError ? serverError.counter : ''} </h1>
                <button className="btn_wrapper" onClick={runRecaptcha}>
                  <BasicButton label="Зареєструватися" width="156px" />
                </button >
                <Recaptcha
                  ref={recaptchaRef}
                  sitekey={SITE_KEY_RECAPTCHA}
                  onResolved={handleSubmit}
                />
                <h1 className="no_account">Вже зареєстровані?
                    <b onClick={() => history.push("/sign_in")}> Увійти</b>
                </h1>
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default inject('authModel')(observer(SignUp));
import React, { useState } from 'react';
import { inject, observer } from "mobx-react";

import ImgBuilder from '../../../../img/ImgBuilder';
import BasicButton from '../../../../res/BasicButton/BasicButton';

import './AdminDashboardHeader.scss';

const AdminDashboardHeader = (props) => {
  const {
    usersAdminModel: {
      changeFilter, refreshFilter, getAllUsers, filter: {
        search,
      }
    },
    adminModel: {
      quarters
    }
  } = props;

  const headerFilter = [
    { name: "Всі клієнти" },
    { name: "Боржники", filter_key: "isDebtor", filter: true },
    { name: "Нові користувачі", filter_key: "isApprowed", filter: true },
    { name: "Дані змінено", filter_key: "isAwaitingConfrim", filter: true },
    { name: "Без кварталу", filter_key: "isQuarter", filter: true },
    ...quarters.map((item) => ({
      "name": item.name,
      "filter": item.name,
      "filter_key": "quarter",
    }))
  ]

  const [activeTab, setActiveTab] = useState(0);

  const onClickFilter = (filter_key, filter, key) => {
    setActiveTab(key)
    refreshFilter();
    if (filter_key && filter) {
      changeFilter(filter_key, filter);
    };
  };

  return <div className="admin_dashbaord_header">
    <div className="filter_wrapper">
      <div className="search">
        <img src={ImgBuilder.search} alt="search" />
        <input
          onChange={(e) => changeFilter('search', e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter")
              getAllUsers();
          }}
          value={search}
          type="text"
          placeholder="Пошук" />
      </div>
      <div className="search" onClick={getAllUsers}>
        <BasicButton label="Шукати" width="100px" />
      </div>
    </div>

    <div className="tabs">
      {headerFilter.map((item, key) => (
        <div key={key} className={`item ${activeTab === key ? "active" : ""}`}
          onClick={() => onClickFilter(item.filter_key, item.filter, key)}
        >
          {item.name}
        </div>
      ))}
    </div>
  </div>
}

export default inject('usersAdminModel', 'adminModel')(observer(AdminDashboardHeader));
import { makeAutoObservable, runInAction } from "mobx";

import ApiService from '../service/ApiService';

export class CountersAdminModel {
    constructor() {
        makeAutoObservable(this);
    };

    counters = [];
    pagination = {
        page: 1,
        total_pages: 1,
        per_page: 10,
    };
    filter = {
        search: '',
        type: 'ASC',
    };

    getCounters = (page) => {
        ApiService.getCounters(page ?? this.pagination.page, this.filter.search, this.filter.type).then(resp => {
            runInAction(() => {
                this.counters = resp.data.data;
                this.pagination = resp.data.meta.pagination;
            });
        });
    };

    changeFilter = (key, value) => {
        if (key === 'search') {
            this.pagination.page = 1;
        }
        this.filter[key] = value;
    };

    refreshFilter = () => {
        this.pagination.page = 1;
        this.filter = {
            search: '',
            type: 'ASC',
        };
    }
}

export const countersAdminModel = new CountersAdminModel();
import React from "react";
import { Route } from 'react-router-dom';
import { inject } from "mobx-react";

const ProtectedRoute = ({ authModel: { sessionToken }, component: Component, ...rest }) => {
    return (
        <Route {...rest} render={
            (props) => {
                if (sessionToken) {
                    return <>
                        <Component {...props} />
                    </>
                } else {
                    window.location.replace("/sign_in")
                }
            }
        } />
    )
}

export default inject('authModel')(ProtectedRoute);
import React, { useState } from 'react';
import { inject, observer } from "mobx-react";
import ApiService from '../../../../../service/ApiService';

import OutsideClickHandler from 'react-outside-click-handler';

import './Comment.scss';

const Comment = (props) => {
  const {
    comment, userId, clickedId, close,
    usersAdminModel: {
      getAllUsers
    }
  } = props;

  const defaultValue = comment;
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(comment ? comment : "")

  const hideMe = () => {
    return userId !== clickedId;
  }

  const saveComentar = () => {
    ApiService.updateComentar(userId, value).then(resp => {
      setEditMode((prevState => !prevState))
      close();
      getAllUsers()
    })
  }

  const removeComentar = () => {
    ApiService.updateComentar(userId, "").then(resp => {
      setValue("");
      close();
      getAllUsers()
    })
  }

  if (hideMe()) {
    return ""
  }
  return <OutsideClickHandler
    onOutsideClick={() => {
      close();
    }}>
    <div className="comment_wrapper">

      {editMode ?
        <>
          <input
            autoFocus={true}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <div className="buttons_wrapper">
            <span className="action cancel"
              onClick={() => {
                setEditMode((prevState => !prevState))
                setValue(defaultValue)
              }}>
              Скасувати
            </span>
            <span className="action edit" onClick={saveComentar}>Зберегти</span>
          </div>
        </>
        : <h1 className="text">{value ? value : "Немає інформації"}</h1>
      }

      {!editMode && comment ?
        <div className="buttons_wrapper">
          <span className="action cancel" onClick={removeComentar}>Видалити</span>
          <span className="action edit"
            onClick={() => setEditMode((prevState => !prevState))}
          >
            Редагувати</span>
        </div> : null
      }

      {!editMode && !comment ?
        <div className="buttons_wrapper">
          <span className="action edit"
            onClick={() => setEditMode((prevState => !prevState))}
          >
            Редагувати</span>
        </div> : null
      }
    </div>
  </OutsideClickHandler >
}

export default inject('usersAdminModel')(observer(Comment));
import React from 'react';
import { inject, observer } from "mobx-react";

import PayPopup from '../../../../components/popups/PayPopup/PayPopup';
import BasicButton from '../../../../res/BasicButton/BasicButton';
import BasicListItem from '../../../../res/BasicListItem/BasicListItem';

import './BillingData.scss';

const BillingData = ({ userModel: { profile, settings } }) => {

    return <div className="billing-data">
        <div className="header">
            <span className="title">До оплати:</span>
        </div>
        <div className="content">
            <div className="price">
                <h1 class="price_money">{profile.debtor ? (profile.debtor / 100).toFixed(2) : "00.00"}</h1>
                <h1 class="price_currency">грн</h1>
            </div>

            <div className="list">
                <BasicListItem label="Передоплата:" value={`${(profile.prepayment / 100).toFixed(2)} грн`} />
                <BasicListItem label="Борг:" value={`${profile.debtor ? (profile.debtor / 100).toFixed(2) : '00.00'} грн`} />
                <BasicListItem label="Неоплачені кВт:" value={`${profile.unpaid_kilowatts ? profile.unpaid_kilowatts : '0'}`} />
                <BasicListItem label="Ціна за кВт:" value={`${settings.bill_per_vat ? (settings.bill_per_vat / 100).toFixed(2) : "00.00"} грн`} />
                <BasicListItem label="Штраф за протермінований платіж:" value={`${settings.extra_bill_percent ? settings.extra_bill_percent : "00.00"}%`} />
            </div>

              <div className="pay_button">
                <PayPopup >
                    <BasicButton disabled ={true} label="Оплатити" width="156px" disabled={!profile.confirmed || !profile.approved} />
                </PayPopup>
            </div>
        </div>
    </div>
}

export default inject('userModel')(observer(BillingData));


//{['rstebnyk@gmail.com', 'ostapfer@gmail.com'].includes(profile.email)&& 
import { makeAutoObservable, runInAction } from "mobx";

import ApiService from '../service/ApiService';

export class AdminModel {
    constructor() {
        makeAutoObservable(this);
    };

    // Questions
    questions = [];
    inbox_counter = null;
    questionsPagination = {};

    //Quarters
    quarters = [];

    // Settings
    settings = {};

    // Questions
    getQuestions = (page) => {
        ApiService.getQuestions(page).then(resp => {
            runInAction(() => {
                this.inbox_counter = resp.data.inbox_counter;
                this.questions = resp.data.data;
                this.questionsPagination = resp.data.meta.pagination;
                this.isGetQuestion = false;
            });
        });
    };

    // Settings
    getSettings = () => {
        ApiService.getSettings().then(resp => {
            runInAction(() => {
                this.settings = {
                    bill_per_vat: resp.data.bill_per_vat / 100,
                    extra_bill_percent: resp.data.extra_bill_percent,
                    accrual_result_day: resp.data.accrual_result_day,
                    admin_notification_day: resp.data.admin_notification_day,
                    penalty_period: resp.data.penalty_period,
                    reminder_day: resp.data.reminder_day,
                }
            })
        })
    };

    //Quarters
    getQuarters = () => {
        ApiService.getQuarters().then((resp) => {
            runInAction(() => {
                this.quarters = resp.data.data;
            })
        })
    };

    refreshAdmin = () => {
        this.getQuarters();
        this.getSettings();
        this.getQuestions(1);
    };
};

export const adminModel = new AdminModel();
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Popup from 'reactjs-popup';
import { inject, observer } from "mobx-react";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Loader from "react-loader-spinner";

import ApiService from '../../../service/ApiService';
import BasicListItem from '../../../res/BasicListItem/BasicListItem';
import BasicButton from '../../../res/BasicButton/BasicButton';
import { decimalRegex } from '../../../const/regular_expressions';

import './PayPopup.scss';

const PayPopup = ({ children, userModel: { unpayedBills, getUnpayedBills, getPayedBills, profile } }) => {

    const [isLoading, setIsLoading] = useState(false);

    const blockScroll = () => {
        if (document.activeElement.type === "number") {
            document.activeElement.blur();
        };
    };

    useEffect(() => {
        document.addEventListener("wheel", blockScroll);
        return () => document.removeEventListener("wheel", blockScroll)
    })

    const pay = (id) => {
        setIsLoading(true);
        ApiService.payBill(id).then(() => {
            alert("Рахунок надіслано Вам на пошту!");
            getUnpayedBills('false');
            getPayedBills('true');
        }).catch(() => {
            alert("Щось пішло не так");
        }).finally(() => {
            setIsLoading(false);
        })
    }

    return (
        <div className="pay-pop-up-wrapper">
            <Popup
                trigger={() => (children)}
                modal
                closeOnDocumentClick={true}
            >
                {close => (
                    <>
                        <div className="pay-pop-up-header">
                            <h1 className="title">Оплати</h1>
                            <div className="pay_all">
                                <Formik
                                    initialValues={{
                                        amount: '',
                                    }}
                                    validationSchema={Yup.object().shape({
                                        amount: Yup.string().required("Обов'язкове поле").matches(decimalRegex, 'Невірний формат суми').test(
                                            "Min amount",
                                            "Мінімальна сума - 5 грн",
                                            (amount) => Number(amount) > 4,
                                        ),
                                    })}
                                    onSubmit={(data, { setFieldValue, setFieldTouched, setSubmitting }) => {
                                        setIsLoading(true);
                                        setSubmitting(true)
                                        ApiService.payAllBills({
                                            amount: data.amount
                                        }).then(() => {
                                            alert("Рахунок надіслано Вам на пошту!");
                                            setFieldValue('amount', '');
                                            setFieldTouched('amount', false);
                                            getUnpayedBills('false');
                                            getPayedBills('true');
                                        }).catch(() => {
                                            alert("Щось пішло не так")
                                        }).finally(() => {
                                            setSubmitting(false);
                                            setIsLoading(false);
                                        })
                                    }}
                                >
                                    {({ errors, touched, values, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
                                        <Form className="form_pay_all">
                                            <div className="form_item">
                                                {(errors.amount && touched.amount) && <span className="error_text error_text_position">{errors.amount}</span>}
                                                <input
                                                    type="number"
                                                    placeholder="Введіть суму (грн)"
                                                    className={`form_input_price ${errors.amount ? "error" : null}`}
                                                    name="amount"
                                                    value={values.amount}
                                                    min = {5}
                                                    onChange={handleChange} />
                                            </div>

                                            <button className="btn_wrapper" disabled = {isSubmitting}>
                                                <BasicButton label="Оплатити" width="120px" onClick={handleSubmit} disabled = {isSubmitting}/>
                                            </button >
                                            <button className="btn_pay_all" type="button" onClick={() => setFieldValue('amount', (profile?.debtor / 100).toFixed(2))}>
                                                Оплатити все
                                            </button>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>

                        <div className="list">
                            <div className="list_header">
                                <span>Дата</span>
                                <span>Сума(грн)</span>
                            </div>
                            <div className="list_content">
                                {unpayedBills.map((item, key) => (
                                    <div key={key} className="pays">
                                        <BasicListItem label={moment(item.created_at).format("DD.MM.YYYY")} value={`${((item.price + item.extra_price - item.part_price) / 100).toFixed(2)}`} />
                                        <div className="btn-wrapper" onClick={() => pay(item.id)} disabled = {isLoading}>
                                            <BasicButton label="Оплатити" width="124px" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="exit" onClick={close}>
                            Закрити
                        </div>

                        {isLoading && <div className="loader_wrapper_pay_popup">
                            <Loader color="#007BB0" type='BallTriangle' width={150} height={150} />
                        </div>}
                    </>
                )}
            </Popup >
        </div>
    )
}

export default inject('userModel')(observer(PayPopup));